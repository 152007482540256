import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { SUPER_ADMIN_URL } from '../../../Auth_API';
import { UserLogin } from '../../../context/AuthContext';

function UserAnalyticsChart() {
    const { superToken } = UserLogin();
    const [chartOptions, setChartOptions] = useState({
        series: [{
            name: "Users",
            data: [] // Initially empty, to be updated with API data,
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false,
                    colors:['#50B482']
                }
            },
            colors: ['#50B482'], 
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: [], // Initially empty, to be updated with API data
            }
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${SUPER_ADMIN_URL}/total_users_registered_count/`, {
                    headers: {
                        Authorization: `Token ${superToken}`,
                    },
                }
                );
                const data = response.data;
                console.log(data, "data")
                const counts = data.map(item => item.count);
                const months = data.map(item => {
                    const date = new Date(item.month);
                    return date.toLocaleString('default', { month: 'short' });
                });

                setChartOptions(prevOptions => ({
                    ...prevOptions,
                    series: [{ ...prevOptions.series[0], data: counts }],
                    options: {
                        ...prevOptions.options,
                        xaxis: { ...prevOptions.options.xaxis, categories: months },
                    },
                }));
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []); // This effect runs once on component mount

    return (
        <div id="user-analytics-chart" className="analytic-chart shadow-sm">
            <ReactApexChart options={chartOptions.options} series={chartOptions.series} type="line" height={300} />
        </div>
    );
}

export default UserAnalyticsChart;
