import React from 'react';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import { UserLogin } from '../../../context/AuthContext';
import { SUPER_ADMIN_URL } from '../../../Auth_API';
import Swal from 'sweetalert2';

function SkimButtons() {
    const {
        superToken,
        showToast,
        setIsErrorOpen,
        cvSkimdescription,
        setIsCVSkimLoading,
        selectedFiles,
        setSkimResponse,
        selectedDescFiles,
        setSelectedDescFiles,
        setSelectedFiles,
        setcvSkimDescription,
        setSelectedZipFolder,
        selectedZipFolder,
        fileInputRef,
        descInputRef,
        zipInputRef,
    } = UserLogin();

    // Function to skimmed data
    const handleCompareCVs = async () => {
        setIsCVSkimLoading(true);
        const formData = new FormData();
        selectedFiles.forEach(file => {
            formData.append('cv_files', file);
        });
        if (selectedZipFolder) {
            formData.append('cv_files', selectedZipFolder);
        }
        if (selectedDescFiles) {
            formData.append('job_description_file', selectedDescFiles);
        }
        formData.append('job_description', cvSkimdescription);
        const swalAlert = Swal.fire({
            title: 'Uploading Files',

            html: 'Please wait while the files are being processed...',
            icon: 'info',
            allowOutsideClick: false,
            timerProgressBar: true,
            customClass: {
                popup: 'custom-swal-popup',
                icon: 'custom-swal-icon', 
            },
            onBeforeOpen: () => {
                Swal.showLoading();
            },
            didOpen: () => {
                Swal.showLoading();
            }
        });

        try {
            const response = await fetch(`${SUPER_ADMIN_URL}/compare-cvs/`, {
                method: 'POST',
                headers: {
                    'Authorization': `Token ${superToken}`,
                },
                body: formData,
                onUploadProgress: (progressEvent) => {
                    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                    Swal.update({
                        title: 'Uploading Files',
                        html: `Please wait while the files are being uploaded... ${percentCompleted}%`,
                        allowOutsideClick: false,
                        onBeforeOpen: () => {
                            Swal.showLoading();
                        }
                    });
                }
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();
            console.log(data, "dfgsdjsdfa")
            setSkimResponse(data);
            showToast("Files has been uploaded");
        } catch (error) {
            showToast("Failed to upload");
            setIsErrorOpen(true);
        } finally {
            setIsCVSkimLoading(false);
            swalAlert.close();
        }
    };
    const handleClear = () => {
        setSelectedFiles([]); // PDF files
        setSelectedDescFiles(""); // Description PDF file
        setcvSkimDescription(""); // Description in text
        setSelectedZipFolder("");
        setSkimResponse({ results: [] }); 
        if (fileInputRef.current) fileInputRef.current.value = '';
        if (descInputRef.current) descInputRef.current.value = '';
        if (zipInputRef.current) zipInputRef.current.value = '';
    };
    

    return (
        <Box display="flex" justifyContent="space-between" p={1} m={1} style={{ marginTop: "50px" }}>
            <Button
                variant="contained"
                className='skim-btns clear-btn'
                onClick={handleClear}
            >
                Clear
            </Button>
            <Button
                variant="contained"
                className='skim-btns submit-btn'
                onClick={handleCompareCVs}
            >
                Submit
            </Button>
        </Box>
    );
}

export default SkimButtons;
