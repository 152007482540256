import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import axios from "axios";
import { UserLogin } from "../../../context/AuthContext";
import { GET_ADMIN_USER_PERCENTAGE } from "../../../Auth_API";

const PieOverview = (props) => {
  const { superToken } = UserLogin();
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({});
  const [chartHeight, setChartHeight] = useState(300);
  const [loaded, setLoaded] = useState(false); 

  const percentageAdminUser = async () => {
    try {
      const response = await axios.get(`${GET_ADMIN_USER_PERCENTAGE}`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
      });
      const data = Object.values(response.data);
      const intArray = data.map((num) => Math.floor(num));
      setSeries(intArray);
      setLoaded(true); 
    } catch (error) {
      console.error("Error fetching admin user percentage:", error);
    }
  };

  useEffect(() => {
    if (superToken) {
      percentageAdminUser();
    }
  }, [superToken]);

  useEffect(() => {
    const handleResize = () => {
      setChartHeight(410);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (loaded) {
      setOptions({
        chart: {
          type: "pie",
        },
        colors: ["#56367F", "#50B482"],
        labels: ["Active Users", "Active Admins"],
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          floating: false,
          fontSize: '14px',
          offsetX: 0,
          offsetY: 5
        },
        tooltip: {
          y: {
            formatter: (value) => {
              const total = series.reduce((sum, current) => sum + current, 0);
              return total > 0 ? `${((value / total) * 100).toFixed(0)}%` : 'N/A';
            }
          }
        },
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 5-0
        //       },
        //       legend: {
        //         position: 'bottom'
        //       }
        //     },
        //   },
        // ],
      });
    }
  }, [series, loaded]); 

  if (!loaded) {
    return <div>Loading chart...</div>;
  }

  return (
    <div id="chart" style={{ marginTop: '20px' }}>
      <ReactApexChart options={options} series={series} type="pie" height={chartHeight} />
    </div>
  );
};

export default PieOverview;
