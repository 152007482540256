import React, { useState, useEffect, useRef } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { GrFormClose } from "react-icons/gr";
import MainLogo from "../../../../assets/img/onboardiq.svg";
import { useNavigate } from "react-router-dom";
import { useTour } from "@reactour/tour";
import cvSkim from "../../../../assets/img/cvskim.png";
import overview from "../../../../assets/img/overview-icon.png";
import organization from "../../../../assets/img/organizations-icon.png";
import organizationadmin from "../../../../assets/img/organization-admin-icon.png";
import logout from "../../../../assets/img/logout.png";
import LogoutModal from './logoutadmin'; // Import the modal component

const Sidebar = ({ setToggle, setsideToggle }) => {
  const { setIsOpen } = useTour();
  const navigate = useNavigate();
  const location = useLocation();
  const [showLogoutModal, setShowLogoutModal] = useState(false); // State for modal visibility
  const sidebarRef = useRef(null); // Ref for the sidebar

  const onClose = () => {
    setsideToggle(false);
  };

  const onSuperAdminLogout = () => {
    localStorage.removeItem("SuperToken");
    navigate("/");
    window.location.reload();
  };

  const handleNavigate = () => {
    setIsOpen(true);
  };

  const isActive = (path) => {
    return location.pathname === path;
  };

  useEffect(() => {
    // Function to close sidebar if click is outside
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setsideToggle(false);
      }
    };

    // Add event listener
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      // Clean up event listener on component unmount
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setsideToggle]);

  const handleLogoutClick = () => {
    setShowLogoutModal(true);
    setsideToggle(false); // Close sidebar when logout is clicked
  };

  return (
    <div
      ref={sidebarRef} // Attach the ref to the sidebar
      className={`sidebar ${setToggle ? "open-sidebar" : ""}`}
      style={{ backgroundColor: "#F5F5F5" }}
    >
      <div className="close-div">
        <GrFormClose onClick={onClose} />
      </div>
      <div className="side-title text-center">
        <NavLink to="/s_admin_dashboard">
          <img src={MainLogo} alt="CDS" className="img-fluid" />
        </NavLink>
      </div>
      <div className="side-content mt-4 d-flex flex-column">
        <ul className="navbar-nav">
          <li className={`nav-items-2 py-3 px-2 mx-3 align-items-center ${isActive('/s_admin_dashboard') ? 'active' : ''}`}>
            <NavLink to="/s_admin_dashboard" className="nav-link py-0 icon-text-gap">
              <img src={overview} alt="overview" /> Overview
            </NavLink>
          </li>
          <li className={`nav-items-2 py-3 px-2 mx-3 align-items-center ${isActive('/s_admin_dashboard/Organization') ? 'active' : ''}`}>
            <NavLink to="/s_admin_dashboard/Organization" onClick={handleNavigate} className="nav-link py-0 icon-text-gap">
              <img src={organization} alt="organization" /> Organizations
            </NavLink>
          </li>
          <li className={`nav-items-2 py-3 px-2 mx-3 align-items-center ${isActive('/s_admin_dashboard/sub_admin') ? 'active' : ''}`}>
            <NavLink to="/s_admin_dashboard/sub_admin" className="nav-link py-0 icon-text-gap">
              <img src={organizationadmin} alt="organization admin" /> Organizational Admin
            </NavLink>
          </li>
          <li className={`nav-items-2 py-3 px-2 mx-3 align-items-center ${isActive('/s_admin_dashboard/cv_skim') ? 'active' : ''}`}>
            <NavLink to="/s_admin_dashboard/cv_skim" className="nav-link py-0 icon-text-gap">
              <img src={cvSkim} alt="cv skim" /> CV Skimming
            </NavLink>
          </li>
        </ul>
        <ul className="navbar-nav" style={{ fontWeight: "normal" }}>
          <li className="nav-items-2 py-3 px-2 mx-3 align-items-center">
            <NavLink to="#" className="nav-link py-0 icon-text-gap" onClick={handleLogoutClick}>
              <img src={logout} alt="logout" /> Log out
            </NavLink>
          </li>
        </ul>
      </div>
      {showLogoutModal && (
        <LogoutModal
          onClose={() => setShowLogoutModal(false)}
          onConfirm={onSuperAdminLogout}
        />
      )}
    </div>
  );
};

export default Sidebar;
