import React, { useEffect, useState } from "react";
import Dashboard from "./Layout/dashboard";
import Graph from "./graph";
import { UserLogin } from "../../../context/AuthContext";
import { useNavigate } from "react-router";
import axios from "axios";
import {
  TOTAL_COUNT_SUB_ADMINS,
  TOTAL_COUNT_USERS,
  TOTAL_COUNT_ORGANIZATION,
  TOTAL_COUNT_ORAL_ASSESSMENT,
  TOTAL_COUNT_PI,
} from "../../../Auth_API";
import MoreAppLoader from "../../Loader/MoreAppLoader";
import CountUp from "react-countup";
import { useTour } from "@reactour/tour";
import Assessments from "../../../assets/img/assessments.png";
import PITests from "../../../assets/img/PI-tests.png";
import registeredusers from "../../../assets/img/registered-users.png";
import validadmins from "../../../assets/img/valid-admins.png";
import organizations from "../../../assets/img/org-total.png";
import Commonicon from "../../../assets/img/green-icon.png";

const Balance = () => {
  const navigation = useNavigate();
  const { superToken, preLoader, setPreLoader, isTourOpen, setIstourOpen } = UserLogin();
  const [totalAdmins, setTotalAdmins] = useState(0);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalOrganization, setTotalOrganization] = useState(0);
  const [TotalAssessments, setTotalAssessments] = useState(0);
  const [totalPI, setTotalPI] = useState(0);
  
  const data = [
    {
      title: "Valid Admins",
      value: totalAdmins,
      icon: validadmins,
    },
    {
      title: "Registered Users",
      value: totalUsers,
      icon: registeredusers,
    },
    {
      title: "Total Organizations",
      value: totalOrganization,
      icon: organizations,
    },
    {
      title: "Assessments",
      value: TotalAssessments,
      icon: Assessments,
    },
    {
      title: "PI's Tests",
      value: totalPI,
      icon: PITests,
    },
  ];

  const countSubAdmins = async () => {
    await axios
      .get(`${TOTAL_COUNT_SUB_ADMINS}`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
      })
      .then((response) => {
        setTotalAdmins(response.data.total_active_subadmins);
      });
  };

  const countUsers = async () => {
    await axios
      .get(`${TOTAL_COUNT_USERS}`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
      })
      .then((response) => {
        setTotalUsers(response.data.total_users);
      });
  };

  const countOrganization = async () => {
    await axios
      .get(`${TOTAL_COUNT_ORGANIZATION}`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
      })
      .then((response) => {
        setTotalOrganization(response.data.total_organizations);
      });
  };

  const countOralAssessments = async () => {
    await axios
      .get(`${TOTAL_COUNT_ORAL_ASSESSMENT}`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
      })
      .then((response) => {
        setTotalAssessments(response.data.total_oral_test_submissions);
      });
  };

  const countPI = async () => {
    await axios
      .get(`${TOTAL_COUNT_PI}`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
      })
      .then((response) => {
        setTotalPI(response.data.total_predictive_index);
      });
  };

  const { isOpen, currentStep, setCurrentStep, setIsOpen } = useTour();

  useEffect(() => {
    if (currentStep >=3) {
      setIsOpen(false);
    }
  }, [currentStep, setIsOpen]);

  // Functions to disable and enable scrolling
  const disableScroll = () => {
    document.body.style.overflow = "hidden";
  };

  const enableScroll = () => {
    document.body.style.overflow = "";
  };

  useEffect(() => {
    const handleBodyScroll = () => {
      if (isOpen) {
        disableScroll();
      } else {
        enableScroll();
        setCurrentStep(0);
      }
    };

    handleBodyScroll();
    return () => {
      enableScroll(); // Ensure scroll is enabled when the component unmounts
    };
  }, [isOpen, setCurrentStep]);

  useEffect(() => {
    const handlePopstate = () => {
      if (superToken) {
        navigation("/s_admin_dashboard");
      }
    };
    if (superToken) {
      countPI();
      countSubAdmins();
      countUsers();
      countOrganization();
      countOralAssessments();
      setTimeout(() => {
        setPreLoader(false);
      }, 2000);
    }
    window.addEventListener("popstate", handlePopstate);
    return () => {
      window.removeEventListener("popstate", handlePopstate);
    };
  }, []);

  return (
    <>
      {preLoader ? <MoreAppLoader /> : null}
      <Dashboard title="Overview">
        <div className="background">
          <div className="s-dashboard-top" data-tour="1">
            {data.map((item, index) => {
              return (
                <div className="total shadow-sm" key={index}>
                  <div className="icon-text-container">
                    <img
                      src={Commonicon}
                      alt="Common icon"
                      className="common-icon "
                    />
                    <p
                      className="cv-title cv-title-font mb-3"
                      style={{ fontWeight: "400", color: "#101828" }}
                    >
                      {item.title}
                    </p>
                  </div>
                  <h3
                    className="content valid-user "
                    style={{ marginLeft: "30px" }}
                  >
                    <CountUp end={item.value} />
                  </h3>
                  <img
                    src={item.icon}
                    alt={`${item.title} icon`}
                    className="item-icon "
                  />
                </div>
              );
            })}
          </div>
          <Graph />
        </div>
      </Dashboard>
    </>
  );
};

export default Balance;
