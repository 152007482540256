import React from "react";
import AuthProvider from "./context/AuthContext";
import SuperAdminStack from "./MainAdminStack/Stack";
import { BrowserRouter as Router } from "react-router-dom";
import { TourProvider } from "@reactour/tour";
import steps from "./components/steps"

function App() {
  return (
    <Router>
      <AuthProvider>
        <TourProvider steps={steps}>
          <SuperAdminStack />
        </TourProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
