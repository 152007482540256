
const steps = [
  {
    selector: '[data-tour="1"]',
    content: (
      <>
        <h3 className="step-heading">User stats</h3>
        <p>All the relevant user stats are shown here.</p>
      </>
    ),
  },
  {
    selector: '[data-tour="2"]',
    content: (
      <>
        <h3 className="step-heading">Reports data</h3>
        <p>Total number of assessment predictive index reports </p>
      </>
    ),

  },
  {
    selector: '[data-tour="3"]',
    content: (
      <>
        <h3 className="step-heading">Top 3 organizations</h3>
        <p>This table shows the top three organizations with most users.</p>
      </>
    ),
  },

  {
    selector: '[data-tour="4"]',
    content: (
      <>
        <h3 className="step-heading">Step 1:</h3>
        <p>You can add multiple organizations from here</p>
      </>
    ),
    highlightedSelectors: [".ReactModal__Content"],
    mutationObservables: [".ReactModal__Overlay"],

  },
  {
    selector: '[data-tour="5"]',
    content: (
      <>
        <h3 className="step-heading">Step 2:</h3>
        <p>You can check list of organizations details.</p>
      </>
    ),
  },
  {
    selector: '[data-tour="6"]',
    content: (
      <>
        <h3 className="step-heading">Step 3:</h3>
        <p>Active and Inactive certain organization.</p>
      </>
    ),
  },
  {
    selector: '[data-tour="7"]',
    content: (
      <>
        <h3 className="step-heading">Step 4:</h3>
        <p>You can Edit or Delete an organization under action.</p>
      </>
    ),
  },
  // {
  //   selector: '[data-tour="8"]',
  //   content: (
  //     <>
  //       <h3 className="step-heading">Step 5:</h3>
  //       <p>Completely delete an organization from here.</p>
  //     </>
  //   ),
   
  // },
  {
    selector: '[data-tour="8"]',
    content: (
      <>
        <h3 className="step-heading">Step 6:</h3>
        <p>Search any organization by entering its name.</p>
      </>
    ),
  },

];

export default steps;
