import React from "react";
import logout from "../../../../assets/img/logoutIcon.png";


const logoutadmin = ({ onClose, onConfirm }) => {
  return (
    <div className="logout-modal">
    <div className="logout-modal-content">
      <span className="logout-modal-close" onClick={onClose}>&times;</span>
      <div className="logout-modal-body">
        <img src={logout} alt="Logout" style={{marginLeft:"30px"}} /> {/* Update the path */}
        <h2 style={{fontWeight:"bold",fontSize:"30px",marginTop:"10px"}}>Want to Logout?</h2>
        <div className="logout-buttons">
          <button className="logout-button" onClick={onConfirm}>LOGOUT</button>
          <button className="cancel-button" onClick={onClose}>NOT NOW</button>
        </div>
      </div>
    </div>
  </div>
  );
};

export default logoutadmin;
