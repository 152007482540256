import React, { useState } from 'react';
import { MdKeyboardArrowUp, MdOutlineKeyboardArrowDown } from "react-icons/md";
import { FaEye } from "react-icons/fa";
import { UserLogin } from '../../../context/AuthContext';
import "./skimStyle.css";
import emailImg from "../../../assets/img/mail.png";
import phoneImg from "../../../assets/img/telephone.png";
import Lottie from "lottie-react";
import Papa from "papaparse";
import { MenuItem, Select } from '@mui/material';
import { motion } from 'framer-motion';

function SkimTable() {
    const { skimResponse } = UserLogin();
    const [heightToggle, setHeightToggle] = useState(-1);
    const [heightState, setHeightState] = useState(false);
    const [selectedScoreRange, setSelectedScoreRange] = useState('');

    const onClickHeightToggle = (index) => {
        if (heightToggle === index) {
            setHeightToggle(-1);
            setHeightState(false);
        } else {
            setHeightToggle(index);
            setHeightState(true);
        }
    };

    const handleExportToCSV = () => {
        const rows = skimResponse.results.map((item, index) => ({
            "Index": index + 1,
            "File Name": item.name,
            "Email": item.email,
            "Phone Number": item.phone_number,
            "Similarity Score": item.cv_score.toFixed(2)
        }));

        const csvData = Papa.unparse(rows);
        const blob = new Blob([csvData], { type: "text/csv" });
        const a = document.createElement("a");
        a.href = URL.createObjectURL(blob);
        a.download = "Skimmed CV Data.csv";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    };

    const styleBasedOnScore = (score) => {
        let color;
        if (score >= 71) color = '#4CAF50';    // Green
        else if (score >= 51) color = '#08a0d1';    // Blue
        else if (score >= 31) color = 'rgb(235 223 110)';    // Yellow
        else color = '#F44336';                     // Red
        return {
            borderLeftColor: color,
            buttonBackgroundColor: color
        };
    };

    const handleScoreRangeChange = (event) => {
        setSelectedScoreRange(event.target.value);
    };

    const getFilteredResults = () => {
        if (!selectedScoreRange || selectedScoreRange === '') {
            return skimResponse?.results;
        }
        const scoreThreshold = parseInt(selectedScoreRange?.split(' ')[1], 10);
        return skimResponse?.results?.filter(item => item.cv_score >= scoreThreshold);
    };
    const filteredResults = getFilteredResults();

    const variants = {
        initial: { opacity: 0, x: 50 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: -50 },
    };

    return (
        <>
            <div style={{ marginTop: "6%", marginBottom: "5%" }}>
                <p className='skim-table-title text-center' style={{ fontSize: '24px', fontWeight: 'bold' }}>Skimmed CV's</p>
                {Object.entries(skimResponse).length > 0 && (
                    <div className='cv-skim-filters'>
                        <div className="download-icon-container d-flex justify-content-between align-items-center">
                            <Lottie
                                animationData={require("../../../assets/animation/download1.json")}
                                loop
                                autoplay
                                className="download-icon px-3"
                                onClick={handleExportToCSV}
                            />
                        </div>
                        <Select
                            value={selectedScoreRange}
                            onChange={handleScoreRangeChange}
                            style={{
                                marginLeft: "20px",
                                marginRight: "10px",
                                marginTop: "20px",
                            }}
                            displayEmpty
                        >
                            <MenuItem value="">All Scores...</MenuItem>
                            <MenuItem value=">= 71">≥ 71%</MenuItem>
                            <MenuItem value=">= 51">≥ 51%</MenuItem>
                            <MenuItem value=">= 31">≥ 31%</MenuItem>
                        </Select>

                    </div>
                )}
                {filteredResults?.length > 0 ? (
                    filteredResults?.map((item, index) => (
                        <div
                            className={`bg-test-div mb-3 mt-3 ${heightToggle === index && "test-height-toggle"}`}
                            key={index}
                            style={{ borderLeft: `5px solid ${styleBasedOnScore(item.cv_score).borderLeftColor}` }}
                        >
                            <motion.div
                                key={item.id}
                                variants={variants}
                                initial="initial"
                                animate="animate"
                                exit="exit"
                                transition={{ duration: 0.5 }}
                            >
                                <div className="bg-test">
                                    <div className="test-flex">
                                        <div className="test-div">
                                            <p className="m-0 test-caption"><strong>{index + 1}. File Name</strong></p>
                                            <p className="mx-3 test-name-width">{item.name}</p>
                                        </div>
                                        <div className="created-div">
                                            <p className="m-0 test-caption"><strong>Similarity Score</strong></p>
                                            <p className="m-0 text-center">{item.cv_score?.toFixed(2)}%</p>
                                        </div>
                                    </div>
                                    <div className="action-space">
                                        <button
                                            type="button"
                                            className="btn btn-primary test-btn-toggle"
                                            style={{ background: styleBasedOnScore(item.cv_score).buttonBackgroundColor, border: "none" }}
                                            onClick={() => onClickHeightToggle(index)}
                                            data-tour="3"
                                        >
                                            {heightToggle === index && heightState ? <MdKeyboardArrowUp /> : <MdOutlineKeyboardArrowDown />}
                                        </button>
                                    </div>
                                </div>
                                <div className="test-description">
                                    <strong>Details</strong>
                                    <div className="mt-2" style={{ fontSize: "16px" }}>
                                        <a href={`mailto:${item.email}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <img src={emailImg} alt='email image' style={{ width: "20px" }} />
                                            {" "}
                                            {item.email}
                                        </a>
                                    </div>
                                    <div className="mt-2" style={{ fontSize: "16px" }}>
                                        <a href={`https://wa.me/${item.phone_number}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                            <img src={phoneImg} alt='phone image' style={{ width: "20px" }} />
                                            {" "}
                                            {item.phone_number}
                                        </a>
                                    </div>
                                </div>


                                <div className="divider my-2"></div>
                                <div className="mt-3 question-add-btn">
                                    <button
                                        type="button"
                                        className="btn btn-outline-secondary"
                                        style={{
                                            borderRadius: "50%",
                                            height: "42px",
                                            width: "42px",
                                        }}
                                        onClick={() => window.open(item.cv_path, "_blank")}
                                    >
                                        <FaEye />
                                    </button>
                                </div>
                            </motion.div>
                        </div>
                    ))
                ) : (
                    <p className="text-center">No matching CVs found.</p>
                )}
                {Object.entries(skimResponse).length === 0 && (
                    <p className="text-center">No CV skimmed yet.</p>
                )}
            </div>
        </>
    )
}

export default SkimTable;
