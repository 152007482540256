import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { UserLogin } from "../../../context/AuthContext";
import { ADMIN_VERIFY_OTP, ADMIN_FORGET_URL } from "../../../Auth_API";
import AppLoader from "../../Loader/AppLoader";
export default function FP_ADMIN_Screen2() {
  let navigation = useNavigate();
  const [countdown, setCountdown] = useState(120);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const { isLoading, setIsLoading, showToast, setIsErrorOpen } = UserLogin();
  const location = useLocation();
  const { forget_pass_email } = location.state || {};
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Format the remaining time in MM:SS
  const formatTime = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Function for otp fields
  const digitValidate = (index, value) => {
    setOtpValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = value.replace(/[^0-9]/g, "");
      return newValues;
    });
  };

  const tabChange = (index) => {
    const inputFields = document.querySelectorAll(".otp");
    if (inputFields.length > index) {
      if (inputFields[index - 1].value !== "") {
        inputFields[index].focus();
      } else if (index > 1 && inputFields[index - 1].value === "") {
        inputFields[index - 2].focus();
      }
    }
  };

  /*** Function to handle verify otp ***/
  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(`${ADMIN_VERIFY_OTP}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          otp: otpValues.join(""),
          username: forget_pass_email,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        showToast("OTP has been verified!");
        navigation("/admin_reset_password", {
          state: {
            reset_pass_email: forget_pass_email,
            otp_admin: otpValues.join(""),
          },
          replace: true,
        });
        setIsLoading(false);
      } else {
        showToast(`${data.error}`);
        setIsErrorOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      showToast("Failed to verify OTP!");
      setIsErrorOpen(true);
      setIsLoading(false);
    }
  };
  /*** Function to handle resend password ***/
  const handleResendOTP = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);
      const response = await fetch(`${ADMIN_FORGET_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: forget_pass_email,
        }),
      });
      if (response.ok) {
        showToast("If you did not recieve an OTP, please check your spam!");
        setIsLoading(false);
        setCountdown(120);
      } else {
        showToast("Failed to send OTP!");
        setIsErrorOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      showToast("Failed to send OTP!");
      setIsErrorOpen(true);
      setIsLoading(false);
    }
  };

  return (
    <div className="admin-container">
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-sm-12 mt-5 bg-white"
            style={{ boxShadow: "0px 2px 20px 12px rgb(0, 0, 0, 0.1)", position: "relative" }}
          >
            <div className="verify-title">OTP Verification</div>
            <form className="verify-form d-md-flex justify-content-md-center mt-5">
              {otpValues.map((value, index) => (
                <input
                  key={index}
                  className="otp mb-sm-0 mb-3"
                  name="otp"
                  type="text"
                  value={value}
                  onChange={(e) => digitValidate(index, e.target.value)}
                  onKeyUp={() => tabChange(index + 1)}
                  maxLength={1}
                />
              ))}
            </form>
            <div className="otp-timer mt-4 ">
              <p>OTP Validate: {formatTime()} </p>
            </div>
            <div
              className=" mt-4 d-flex flex-sm-row flex-column justify-content-center align-items-center mb-3"
              style={{ columnGap: "2rem" }}
            >
              <button
                className="reset-btn-modify mt-sm-4 mt-2 mb-sm-4 mb-2 customBtn"
                style={{ background: "#5cb3cf" }}
                onClick={handleVerify}
              >
                <span className="reset-text">Verify OTP</span>
              </button>
              <button
                className="reset-btn-modify mt-sm-4 mt-2 mb-sm-4 mb-2 customBtn"
                onClick={(e) => handleResendOTP(e)}
                style={{ background: "#979797" }}
              >
                <span className="reset-text">Resend OTP</span>
              </button>
            </div>
            {isLoading ? <AppLoader /> : null}
          </div>
        </div>
      </div>
    </div>
  );
}
