import React from 'react';
import Dashboard from "../AdminView/AdminDashboard/Layout/dashboard";
import UploadCV_Desc from './SkimmingSections/UploadCV_Desc';
import SkimFields from './SkimmingSections/SkimFields';
import SkimButtons from './SkimmingSections/SkimButtons';
import SkimTable from './SkimmingSections/SkimTable';

function SkimMain() {
    return (
        <>
            <Dashboard title="CV Skimming">
                <div className='upload-cv-container'>
                    <UploadCV_Desc />
                    <SkimFields />
                    <SkimButtons />
                    <SkimTable />
                </div>
                {/* {isCVSkimLoading} */}
            </Dashboard>
        </>
    )
}

export default SkimMain