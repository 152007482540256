import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import axios from 'axios';
import { UserLogin } from '../../../context/AuthContext';
import { SUPER_ADMIN_URL } from '../../../Auth_API';

function AdminAnalyticsChart() {
    const { superToken } = UserLogin();
    const [chartOptions, setChartOptions] = useState({
        series: [{
            name: "Admins",
            data: [] 
        }],
        options: {
            chart: {
                height: 350,
                type: 'line',
                zoom: {
                    enabled: false
                }
            },
            colors: ['#56367F'], 
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: 'straight'
            },
            grid: {
                row: {
                    colors: ['#f3f3f3', 'transparent'],
                    opacity: 0.5
                },
            },
            xaxis: {
                categories: [], 
            }
        }
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(
                    `${SUPER_ADMIN_URL}/subadmin_registered_count`, {
                    headers: {
                        Authorization: `Token ${superToken}`,
                    },
                });
                const data = response.data;
                console.log(data, "ghfjgfsdj")
                const counts = data.map(item => item.count);
                const months = data.map(item => {
                    const date = new Date(item.month);
                    return date.toLocaleString('default', { month: 'short' });
                });

                setChartOptions(prevOptions => ({
                    ...prevOptions,
                    series: [{ ...prevOptions.series[0], data: counts }],
                    options: {
                        ...prevOptions.options,
                        xaxis: { ...prevOptions.options.xaxis, categories: months },
                    },
                }));
            } catch (error) {
                console.error('Error fetching chart data:', error);
            }
        };

        fetchData();
    }, []); 

    return (
        <div id="analytic-chart" className="analytic-chart shadow-sm">
            <ReactApexChart options={chartOptions.options} series={chartOptions.series} type="line" height={300} />
        </div>
     
    );
}

export default AdminAnalyticsChart;
