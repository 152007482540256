import * as React from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Dashboard from "../Layout/dashboard";
import { TableHead } from "@mui/material";
import { FaTrash } from "react-icons/fa";
import "./organization.css";
import { LiaEditSolid } from "react-icons/lia";
import CloseIcon from "@mui/icons-material/Close";
import {
  S_ADMIN_ALL_ORGANIZATION,
  DEL_ADMIN_ORGANIZATION,
  S_ADMIN_EDIT_ORGANIZATION,
  S_ADMIN_ADD_ORGANIZATION,
  ADMIN_TOGGLE_STATUS,
} from "../../../../Auth_API";
import { UserLogin } from "../../../../context/AuthContext";
import { useEffect, useState } from "react";
import axios from "axios";
import AppLoader from "../../../Loader/AppLoader";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { FaSearch } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountryFlag from "react-country-flag";
import { flagObject } from "./flags";
import { Toolbar } from "@mui/material";
import { useTour } from "@reactour/tour";
import customCloseIcon from "../../../../assets/img/cross.png";

interface TablePaginationActionsProps {
  count: number;
  page: number;
  rowsPerPage: number;
  onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}
function TablePaginationActions(props: TablePaginationActionsProps) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;
  const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, 0);
  };
  const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page - 1);
  };
  const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, page + 1);
  };
  const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };
  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
        {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}
function createData(
  id: number,
  name: string,
  address: string,
  city: string,
  state: string,
  country: string,
  website: string,
  phone_number: string,
  is_active: boolean,
  postal_code: string
) {
  return {
    id,
    name,
    address,
    city,
    state,
    country,
    website,
    phone_number,
    is_active,
    postal_code,
  };
}

export default function CustomPaginationActionsTable() {
  const { isOpen, currentStep, setCurrentStep, setIsOpen } = useTour();
  const [isExpanded, setIsExpanded] = useState(false);
  const { showToast, setIsErrorOpen, superToken, isLoading, setIsLoading, countryCodes, run, setRun, steps, } = UserLogin();
  let adminToken = superToken.split("\\").join("").split('"').join("");
  const [showModal, setShowModal] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [values, setValues] = useState({
    name: "",
    address: "",
    country: "",
    state: "",
    city: "",
    website: "",
    protocol: "",
    phone_number: "",
    postal_code: "",
  });

  /* Functions for Search Input Field */
  const handleSearchClick = () => {
    setIsExpanded(!isExpanded);
  };

  function getCountryName(code) {
    for (const countryName in countryCodes) {
      if (countryName === code) {
        setSelectedCountry(countryCodes[countryName]);
      }
    }
  }

  const extractDomain = (url) => {
    let domain = url.replace(/^https?:\/\//, "");
    domain = domain.replace(/^www\./, "");
    domain = domain.split("/")[0];
    return domain;
  };

  const [selectedCountry, setSelectedCountry] = useState("");

  const handleCountryChange = (event) => {
    const selectCountryName = event.target.selectedOptions[0].label;
    setSelectedCountry(event.target.value);
    setValues({ ...values, country: selectCountryName });
  };

  const [errorFields, setErrorFields] = useState({});
  const [toggleID, setToggleID] = useState(null);
  const [toggleModal, setToggleModal] = useState(false);
  const [flagImg, setFlagImg] = useState("");

  const onSubmitAddOrganization = async (values) => {
    const { name, address, city, state, country, website, phone_number, protocol, postal_code } =
      values;
    setIsLoading(true);
    await axios
      .post(
        `${S_ADMIN_ADD_ORGANIZATION}`,
        JSON.stringify({
          name: name,
          description: "An example organization for testing purposes",
          address: address,
          city: city,
          state: state,
          country: country,
          postal_code: postal_code,
          phone_number: phone_number,
          website: protocol + website,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${superToken}`,
          },
        }
      )
      .then((response) => {
        fetchOrganizationData();
        showToast("Organization Added Successfully");
      })
      .catch((error) => {
        console.log(error.response);
        if (error.response.status === 400) {
          debugger;
          if (error.response.data.name && error.response.data.website) {
            showToast(`Organization with the name and website already exist.`);
            setIsErrorOpen(true);
          } else if (error.response.data.name) {
            showToast(`${error.response.data.name[0]}`);
            setIsErrorOpen(true);
          } else if (error.response.data.error) {
            showToast("Organization with this website already exist");
            setIsErrorOpen(true);
          } else if (error.response.data["error-2"]) {
            showToast("Organization with this website already exist");
            setIsErrorOpen(true);
          } else if (error.response.data.detail) {
            fetchOrganizationData();
          } else {
            showToast(`${error.response.data.website}`);
            setIsErrorOpen(true);
          }
        }
      });
    setIsLoading(false);
  };

  // useEffect(() => {
  //   console.log("modal id", modalID)
  //   console.log("supertoken", superToken);
  // });

  const onSubmitEditOrganization = async (values) => {
    const { name, address, city, state, country, website, protocol, phone_number, postal_code } =
      values;
    setIsLoading(true);
    try {
      const response = await fetch(`${S_ADMIN_EDIT_ORGANIZATION}${modalID}/`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${superToken}`,
        },
        body: JSON.stringify({
          name: name,
          description: "An example organization for testing purposes",
          address: address,
          city: city,
          state: state,
          country: country,
          postal_code: postal_code,
          phone_number: phone_number,
          website: protocol + website,
        }),
      });
      fetchOrganizationData();
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        showToast("Organization Updated Successfully");
      } else {
        if (response.status === 400) {
          if (data.website && data.name) {
            showToast("Organization with the name and website already exist.");
            setIsErrorOpen(true);
          } else if (data.website) {
            showToast(`${data.website}`);
            setIsErrorOpen(true);
          } else if (data.name) {
            showToast(`${data.name}`);
            setIsErrorOpen(true);
          } else {
            showToast("Update Organization Request Failed");
            setIsErrorOpen(true);
          }
        }
      }
    } catch (error) {
      console.error("Error:", error.message);
    }
    setIsLoading(false);
  };

  const [modalID, setModalID] = useState("");
  const [statusMsg, setStatusMsg] = useState(null);
  const openModal = (id) => {
    setShowModal(true);
    setModalID(id);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const [organData, setOrganizationData] = useState({});
  var rows = Object.entries(organData).map((item, index) => {
    return createData(
      item[1].id,
      item[1].name,
      item[1].address,
      item[1].city,
      item[1].state,
      item[1].country,
      item[1].website,
      item[1].phone_number,
      item[1].is_active,
      item[1].postal_code
    );
  });

  // rows = rows.sort((a, b) => (a.name < b.name ? -1 : 1));
  const filteredRows =
    searchInput === ""
      ? rows
      : rows.filter((row) => {
        return Object.values(row).some((value) =>
          value.toString().toLowerCase().includes(searchInput.toLowerCase())
        );
      });

  const fetchOrganizationData = async () => {
    try {
      await axios
        .get(S_ADMIN_ALL_ORGANIZATION, {
          headers: {
            Authorization: `Token ${adminToken}`,
            "Content-Type": "application/json",
          },
        })
        .then((response) => {
          const sortedData = response.data.sort((a, b) => a.name.localeCompare(b.name));
          setOrganizationData(sortedData);
        });
    } catch (error) {
      console.log("Failed to fetch", error.message);
    }
  };

  useEffect(() => {
    fetchOrganizationData();
  }, []);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;
  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const toggleStatus = async (id) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${ADMIN_TOGGLE_STATUS}${id}/`, {
        method: "POST",
        headers: {
          Authorization: `Token ${adminToken}`,
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      showToast(`${data.status === "active" ? "Active" : "Inactive"} Request Successfull!`);
      fetchOrganizationData();
    } catch (error) {
      console.log("Failed Required", error);
    }
    setIsLoading(false);
  };

  const onChangeEditForm = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrorFields({ ...errorFields, [name]: "" });
  };

  const validate = (values) => {
    let errors = {};
    const nameRegex = /^(?=.*[a-zA-Z])[\w\d\s]+$/; // Regex for name (allowing combination of alphabets and numbers, along with spaces)
    const addressRegex = /^[a-zA-Z0-9\s,#'-]*$/; // Regex for address (allowing alphanumeric characters, spaces, comma, apostrophe, and hyphen)
    const cityRegex = /^[a-zA-Z\s]+$/; // Regex for city (allowing alphabets and spaces only)
    const stateRegex = /^[a-zA-Z\s]+$/; // Regex for state (allowing alphabets and spaces only)
    const postalCodeRegex = /^[0-9]+$/; // Regex for postal code (allowing digits only)

    if (!values.name || !nameRegex.test(values.name)) {
      errors.name = "Invalid organization name";
    }
    if (!values.address || !addressRegex.test(values.address)) {
      errors.address = "Invalid address";
    }
    if (!values.city || !cityRegex.test(values.city)) {
      errors.city = "Invalid city";
    }
    if (!values.state || !stateRegex.test(values.state)) {
      errors.state = "Invalid state";
    }
    if (!values.postal_code || !postalCodeRegex.test(values.postal_code)) {
      errors.postal_code = "Invalid postal code";
    }
    if (!values.country) {
      errors.country = "Country is required";
    }
    if (!values.protocol) {
      errors.protocol = "Protocol is required";
    }
    if (!values.phone_number || values.phone_number.replace(/\D/g, '').length < 9) {
      errors.phone_number = "Invalid phone number";
    }
    if (!values.website) {
      errors.website = "Website URL is required";
    } else if (!/^(?!.*(http|https|www))[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(values.website)) {
      errors.website = "Invalid website URL";
    }
    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(values);
    const validationErrors = validate(values);
    const modifiedPhoneNumber = values.phone_number.replace("+", "");
    const updatedValues = { ...values, phone_number: modifiedPhoneNumber };
    if (Object.keys(validationErrors).length > 0) {
      setErrorFields(validationErrors);
    } else {
      onSubmitEditOrganization(updatedValues);
      setValues({
        name: "",
        address: "",
        country: "",
        state: "",
        city: "",
        website: "",
        protocol: "",
        phone_number: "",
        postal_code: "",
      });
      closeModal();
    }
  };

  // Functions to disable and enable scrolling
  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = '';
  };

  useEffect(() => {
    if (currentStep >= 9) {
      setIsOpen(false);
      // setCurrentStep(3);
    }
    if (currentStep < 3) {
      setCurrentStep(3)
      // setCurrentStep(3);
    }
    console.log("currentstep:", currentStep)
  }, [currentStep, setIsOpen]);

  useEffect(() => {
    const handleBodyScroll = () => {
      if (isOpen) {
        disableScroll();
      } else {
        enableScroll();
        if (currentStep < 3)
          setCurrentStep(3);
      }
    };

    handleBodyScroll();
    return () => {
      enableScroll();
    };
  }, [isOpen, setCurrentStep, currentStep]);


  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [otpValues, setOtpValues] = useState(["", "", "", "", "", ""]);
  const [countdown, setCountdown] = useState(120);
  const [orgRowID, setOrgRowID] = useState(null);


  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(interval);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(interval);
  }, []);

  // Format the remaining time in MM:SS
  const formatTime = () => {
    const minutes = Math.floor(countdown / 60);
    const seconds = countdown % 60;
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  // Function for otp fields
  const digitValidate = (index, value) => {
    setOtpValues((prevValues) => {
      const newValues = [...prevValues];
      newValues[index] = value.replace(/[^0-9]/g, "");
      return newValues;
    });
  };

  const tabChange = (index) => {
    const inputFields = document.querySelectorAll(".otp");
    if (inputFields.length > index) {
      if (inputFields[index - 1].value !== "") {
        inputFields[index].focus();
      } else if (index > 1 && inputFields[index - 1].value === "") {
        inputFields[index - 2].focus();
      }
    }
  };



  const onDeleteOrganization = async (e, id) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("supertoken", superToken);
    console.log("id", id);
    try {
      const response = await axios.post(
        `${DEL_ADMIN_ORGANIZATION}${id}/`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${superToken}`,
          },
        }
      );
      console.log(response, "response after delete click ");
      if (response.status === 200) {
        setIsLoading(false);
        showToast(response.data.message);
        setShowConfirmDeleteModal(true);
        console.log("otp sent to delete admin");
        setCountdown(120);
      }
    } catch (error) {
      showToast(`${error.response.data.error}`);
      setIsErrorOpen(true);
      console.log("Failed to delete Sub Admin", error);
      setIsLoading(false)
    }
    setIsLoading(false);
  };

  const handleSendOTPToDelete = async (e,rowId) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("supertoken", superToken);
    console.log("id", orgRowID);
    try {
      setIsLoading(true);
      const response = await axios.delete(`${DEL_ADMIN_ORGANIZATION}${rowId}/`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
        data: {
          otp: otpValues.join(""),
        },
      });
      console.log(response, "response after delete click ");

      if (response.status === 204) {
        showToast("OTP has been verified!");
        fetchOrganizationData();
        setShowConfirmDeleteModal(false);
        setIsLoading(false);
      }
    } catch (error) {
      setIsErrorOpen(true);
      showToast(error.response.data.error);
      setIsLoading(false);
      console.log("error", error);
    }
  };

  return (
    <>
      {isLoading ? <AppLoader /> : null}
      <div style={{ background: "#EEEEF4" }}>
        <Dashboard title="Organization" >

          <div style={{ marginTop: "5%" }}>
            <div className="d-sm-flex justify-content-between add-btn-admin">

              {/* Search field */}
              <div className="search-field-organ mb-sm-0">
                <Toolbar style={{ padding: "0px" }} data-tour="8">
                  <form
                    role="search"
                    style={{
                      borderRadius: "18px",
                      height: "60px",
                      alignItems: "center",
                    }}
                  >
                    <div
                      className={`search-container ${isExpanded ? "expanded" : ""}`}
                    >
                      <button onClick={handleSearchClick} className="search-button">
                        <i className="fa fa-search"></i>
                      </button>
                      <input
                        className="search-input"
                        type="text"
                        placeholder="Search"
                        onClick={handleSearchClick}
                        onBlur={() => setIsExpanded(false)}
                        onChange={(e) => {
                          setSearchInput(e.target.value);
                        }}
                        value={searchInput}
                      />
                    </div>
                  </form>
                </Toolbar>
              </div>

              <button
                type="button"
                className="btn btn-primary btn-primary-responsivesness"
                data-tour="4"
                style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', border: "none", height: "50px", fontSize: "16px" }}
                onClick={() => {
                  setShowAddModal(true);
                }}
              >
                Add Organizations
              </button>
            </div>
            <div className="org-table ">
              <div data-tour="5">
                <TableContainer component={Paper} className="rounded-3 table-container"
                 style={{ maxWidth: '100%', overflowX: 'auto' }} >
                  <Table sx={{ minWidth: 1400 }} aria-label="custom pagination table">
                    <TableHead>
                      <TableRow className="" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', }}>
                        <TableCell>
                          <strong style={{ fontWeight: "700", color: "white" }}>
                            Organization Name
                          </strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontWeight: "700", color: "white" }}>City</strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontWeight: "700", color: "white" }}>State</strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontWeight: "700", color: "white" }}>Country</strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontWeight: "700", color: "white" }}>Website</strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontWeight: "700", color: "white" }}>Phone Number</strong>
                        </TableCell>
                        <TableCell>
                          <strong style={{ fontWeight: "700", color: "white" }}>Status</strong>
                        </TableCell>
                        <TableCell data-tour="7" >
                          <strong style={{ fontWeight: "700", color: "white" }}>Action</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {(rowsPerPage > 0
                        ? filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : filteredRows
                      ).map((row, index) => (
                        <TableRow key={row.id}>
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.city}</TableCell>
                          <TableCell>{row.state}</TableCell>
                          <TableCell>{row.country}</TableCell>
                          <TableCell>{row.website}</TableCell>
                          <TableCell>+{row.phone_number}</TableCell>
                          <TableCell >
                            {row.is_active === true ? (
                              <span className="active-status" style={{ background: "#50B482" }} >Active</span>
                            ) : (
                              <span className="inactive-status bg-warning" style={{ backgroundColor: "rgb(255, 193, 7)" }}>Inactive</span>
                            )}
                          </TableCell>
                          <TableCell>
                            <label class="switch" data-tour="6">
                              <input
                                type="checkbox"
                                checked={row.is_active === true ? false : true}
                                onClick={() => {
                                  // toggleStatus(row.id)
                                  setStatusMsg(row.is_active);
                                  setToggleID(row.id);
                                  setToggleModal(true);
                                }}
                              />
                              <span class="slider round"></span>
                            </label>
                            <button
                              className="btn-edit-organ"
                              // data-tour="7"
                              onClick={() => {
                                openModal(row.id);
                                setErrorFields({});
                                let protocol = "";
                                if (row.website.startsWith("https://")) {
                                  protocol = "https://www.";
                                } else if (row.website.startsWith("http://")) {
                                  protocol = "http://www.";
                                }

                                setValues({
                                  name: row.name,
                                  address: row.address,
                                  city: row.city,
                                  state: row.state,
                                  country: row.country,
                                  website: extractDomain(row.website),
                                  protocol: protocol,
                                  phone_number: row.phone_number,
                                  postal_code: row.postal_code,
                                });
                                getCountryName(row.country);
                              }}
                            >
                              <LiaEditSolid />
                            </button>
                            <button className="btn-del mt-1" onClick={(e) => {onDeleteOrganization(e,row.id); setOrgRowID(row.id)}} data-tour="8">
                              <FaTrash />
                            </button>
                          </TableCell>
                        </TableRow>
                      ))}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={10} />
                        </TableRow>
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TablePagination
                          rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
                          colSpan={10}
                          count={rows.length}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          SelectProps={{
                            inputProps: {
                              "aria-label": "rows per page",
                            },
                            native: true,
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                          ActionsComponent={TablePaginationActions}
                          sx={{
                            marginTop: 1, // Add margin-top

                          }}
                        />
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </div>
            </div>

            {/* Modal to edit organization */}
            <div
              className={`modal fade ${showModal ? "show" : ""}`}
              style={{ display: showModal ? "block" : "none", overflowY: "hidden" }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <form onSubmit={handleSubmit}>
                    <div className="modal-header" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', color: 'white' }}>
                      <h5 className="modal-title" id="exampleModalLabel">
                        Edit Organizations
                      </h5>
                      <button
                        type="button"
                        className="btn-close"
                        onClick={closeModal}
                        aria-label="Close"
                        style={{ background: `url(${customCloseIcon}) no-repeat center center`, backgroundSize: 'cover', border: 'none', opacity: "1" }}
                      ></button>
                    </div>
                    <div className="modal-body scroll-modal-body">
                      <div className="mb-3">
                        <label htmlFor="inputOrganization" className="small mb-1">
                          Organization Name <span className="required">*</span>
                        </label>
                        <input
                          type="text"
                          name="name"
                          className="form-control"
                          placeholder="Organization Name"
                          value={values.name}
                          onChange={(e) => onChangeEditForm(e)}
                        />
                        {errorFields.name && <div className="error-message">{errorFields.name}</div>}
                      </div>
                      <div className="row gx-3 mb-3">
                        <div className="col-md-6">
                          <label htmlFor="inputAddress" className="small mb-1">
                            Address <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="address"
                            className="form-control"
                            placeholder="Address"
                            value={values.address}
                            onChange={(e) => onChangeEditForm(e)}
                          />
                          {errorFields.address && (
                            <div className="error-message">{errorFields.address}</div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="inputAddress" className="small mb-1">
                            City <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="city"
                            className="form-control"
                            placeholder="City"
                            value={values.city}
                            onChange={(e) => onChangeEditForm(e)}
                          />
                          {errorFields.city && <div className="error-message">{errorFields.city}</div>}
                        </div>
                      </div>
                      <div className="row gx-3 mb-3">
                        <div className="col-md-6">
                          <label htmlFor="inputState" className="small mb-1">
                            State <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="state"
                            className="form-control"
                            placeholder="State"
                            value={values.state}
                            onChange={(e) => onChangeEditForm(e)}
                          />
                          {errorFields.state && (
                            <div className="error-message">{errorFields.state}</div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="inputCountry" className="small mb-1">
                            Country <span className="required">*</span>
                          </label>
                          <span className="d-flex align-items-center">
                            <select
                              id="country"
                              className="form-control"
                              onChange={handleCountryChange}
                              value={selectedCountry}
                            >
                              {Object.entries(countryCodes).map((item, index) => {
                                return <option value={item[1]}>{item[0]}</option>;
                              })}
                            </select>
                            <CountryFlag
                              style={{
                                width: "3rem",
                                position: "absolute",
                                right: "5px",
                              }}
                              countryCode={selectedCountry}
                              svg
                            />
                          </span>
                          {errorFields.country && (
                            <div className="error-message">{errorFields.country}</div>
                          )}
                        </div>
                      </div>
                      <div className="mb-3">
                        <label htmlFor="inputWebsite" className="small mb-1">
                          Website <span className="required">*</span>
                        </label>
                        <div className="d-sm-flex d-block website-protocol-area">
                          <div className="mb-sm-0 mb-3">
                            <select
                              name="protocol"
                              className="form-control protocol-field"
                              onChange={(e) => onChangeEditForm(e)}
                              value={values.protocol}
                              placeholder="Protocol"
                            >
                              <option value="" disabled>
                                Select an option
                              </option>
                              <option value="http://www.">http://www</option>
                              <option value="https://www.">https://www</option>
                            </select>
                            {errorFields.protocol && (
                              <div className="error-message">{errorFields.protocol}</div>
                            )}
                          </div>
                          <div style={{ flex: "1" }}>
                            <input
                              type="text"
                              name="website"
                              className="form-control"
                              placeholder="http://www.abc.com"
                              value={values.website}
                              onChange={(e) => onChangeEditForm(e)}
                            />
                            {errorFields.website && (
                              <div className="error-message">{errorFields.website}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="row gx-3 mb-3">
                        <div className="col-md-6">
                          <label htmlFor="inputPhoneNumber" className="small mb-1">
                            Phone Number <span className="required">*</span>
                          </label>
                          <PhoneInput
                            inputClass="form-control "
                            containerClass=" phone-input"
                            country={"us"}
                            value={values.phone_number}
                            inputProps={{
                              name: "phone_number",
                              onChange: (e) => {
                                onChangeEditForm(e);
                              },
                              maxLength: 20,
                            }}
                          />
                          {errorFields.phone_number && (
                            <div className="error-message">{errorFields.phone_number}</div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="inputPostalCode" className="small mb-1">
                            Postal Code <span className="required">*</span>
                          </label>
                          <input
                            type="text"
                            name="postal_code"
                            className="form-control"
                            placeholder="Postal Code"
                            value={values.postal_code}
                            onChange={(e) => onChangeEditForm(e)}
                          />
                          {errorFields.postal_code && (
                            <div className="error-message">{errorFields.postal_code}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn org-btn-close"
                        style={{ backgroundColor: "#808080" }}
                        onClick={() => {
                          closeModal();
                          setErrorFields(false);
                        }}
                      >
                        Close
                      </button>
                      <button type="submit" className="btn org-btn" style={{ background: 'linear-gradient(90deg, #56367F 0%, #3A5697 100%)' }}>
                        Update
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            {/* Add organization modal */}
            <div
              className={`modal fade ${showAddModal ? "show" : ""}`}
              style={{
                display: showAddModal ? "block" : "none",
                overflowY: "scroll",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <Formik
                    initialValues={{
                      name: "",
                      address: "",
                      city: "",
                      state: "",
                      country: "",
                      protocol: "",
                      website: "",
                      phone_number: "",
                      postal_code: "",
                    }}
                    validate={(values) => {
                      const errors = {};
                      const nameRegex = /^(?=.*[a-zA-Z])[\w\d\s]+$/; // Regex for name (allowing combination of alphabets and numbers, along with spaces)
                      const cityRegex = /^[a-zA-Z\s]+$/; // Regex for city (allowing alphabets and spaces only)
                      const stateRegex = /^[a-zA-Z\s]+$/; // Regex for state (allowing alphabets and spaces only)
                      const postalCodeRegex = /^[0-9]+$/; // Regex for postal code (allowing digits only)

                      if (!values.name || !nameRegex.test(values.name)) {
                        errors.name = "Invalid organization name";
                      }
                      if (!values.address) {
                        errors.address = "Invalid address";
                      }
                      if (!values.city || !cityRegex.test(values.city)) {
                        errors.city = "Invalid city";
                      }
                      if (!values.state || !stateRegex.test(values.state)) {
                        errors.state = "Invalid state";
                      }
                      if (!values.postal_code || !postalCodeRegex.test(values.postal_code)) {
                        errors.postal_code = "Invalid postal code";
                      }
                      if (!values.country) {
                        errors.country = "Country is required";
                      }
                      if (!values.protocol) {
                        errors.protocol = "Protocol is required";
                      }
                      if (!values.phone_number || values.phone_number.replace(/\D/g, '').length < 9) {
                        errors.phone_number = "Invalid phone number";
                      }
                      if (!values.website) {
                        errors.website = "Website URL is required";
                      } else if (!/^(?!.*(http|https|www))[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+$/.test(values.website)) {
                        errors.website = "Invalid website URL";
                      }
                      return errors;
                    }}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                      onSubmitAddOrganization(values);
                      setShowAddModal(false);
                      resetForm();
                      setSubmitting(false);
                      setFlagImg("");
                    }}
                  >
                    {({ isSubmitting }) => (
                      <Form>
                        <div className="modal-header" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', color: 'white' }}>
                          <h5 className="modal-title" id="exampleModalLabel">
                            Add Organizations
                          </h5>
                          <button
                            type="button"

                            className="btn-close"
                            onClick={() => setShowAddModal(false)}
                            aria-label="Close"
                            style={{ background: `url(${customCloseIcon}) no-repeat center center`, backgroundSize: 'cover', border: 'none', opacity: "1" }}>
                          </button>
                        </div>
                        <div className="modal-body scroll-modal-body">
                          <div className="mb-3 heading-style">
                            <label htmlFor="inputOrganization" className="small mb-1">
                              Organization Name <span className="required">*</span>
                            </label>
                            <Field
                              type="text"
                              name="name"
                              className="form-control "
                              placeholder="Organization Name"
                            />
                            <ErrorMessage name="name" component="div" className="error-message" />
                          </div>
                          <div className="row gx-3 mb-3 heading-style">
                            <div className="col-md-6">
                              <label htmlFor="inputAddress" className="small mb-1">
                                Address <span className="required">*</span>
                              </label>
                              <Field
                                type="text"
                                name="address"
                                className="form-control "
                                placeholder="Address"
                              />
                              <ErrorMessage name="address" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="inputAddress" className="small mb-1">
                                City <span className="required">*</span>
                              </label>
                              <Field
                                type="text"
                                name="city"
                                className="form-control"
                                placeholder="City"
                              />
                              <ErrorMessage name="city" component="div" className="error-message" />
                            </div>
                          </div>
                          <div className="row gx-3 mb-3 heading-style">
                            <div className="col-md-6">
                              <label htmlFor="inputState" className="small mb-1">
                                State <span className="required">*</span>
                              </label>
                              <Field
                                type="text"
                                name="state"
                                className="form-control "
                                placeholder="State"
                              />
                              <ErrorMessage name="state" component="div" className="error-message" />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="inputCountry" className="small mb-1">
                                Country <span className="required">*</span>
                              </label>
                              {flagImg && <img src={flagImg} className="flag-img" alt="" />}
                              <Field
                                as="select"
                                id="select-country"
                                name="country"
                                onBlur={(e) => {
                                  const selectedCountryName = e.target.value;
                                  const selectedCountry = flagObject.find(
                                    (item) => item.name === selectedCountryName
                                  );
                                  if (selectedCountry) {
                                    setFlagImg(selectedCountry.image);
                                  } else {
                                    setFlagImg("");
                                  }
                                }}
                                className="form-control"
                              >
                                <option value="">Country</option>
                                {flagObject.map((item, index) => {
                                  return (
                                    <option key={item.code} value={item.name}>
                                      {" "}
                                      {item.name}
                                    </option>
                                  );
                                })}
                              </Field>
                              <ErrorMessage name="country" component="div" className="error-message" />

                            </div>
                          </div>
                          <div className="mb-3 heading-style">
                            <label htmlFor="inputWebsite" className="small mb-1">
                              Website <span className="required">*</span>
                            </label>
                            <div className="d-sm-flex d-block website-protocol-area">
                              <div className="mb-sm-0 mb-3">
                                <Field
                                  as="select"
                                  name="protocol"
                                  className="form-control protocol-field "
                                  placeholder="Protocol"
                                >
                                  <option value="" disabled>
                                    Select Protocol
                                  </option>
                                  <option value="http://www.">http://www</option>
                                  <option value="https://www.">https://www</option>
                                </Field>
                                <ErrorMessage
                                  name="protocol"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                              <div style={{ flex: "1" }}>
                                <Field
                                  type="text"
                                  name="website"
                                  className="form-control"
                                  placeholder="Website"
                                />
                                <ErrorMessage
                                  name="website"
                                  component="div"
                                  className="error-message"
                                />
                              </div>
                            </div>
                          </div>

                          <div className="row gx-3 mb-3 heading-style">
                            <div className="col-md-6">
                              <label htmlFor="inputPhoneNumber" className="small mb-1">
                                Phone Number <span className="required">*</span>
                              </label>

                              <Field name="phone_number" >
                                {({ field, form }) => (
                                  <PhoneInput
                                    inputClass="form-control "
                                    containerClass=" phone-input"
                                    {...field}
                                    country={"us"}
                                    inputProps={{
                                      name: "phone_number",
                                      maxLength: 20,
                                      onBlur: () => form.setFieldTouched("phone_number", true),
                                    }}
                                    onChange={(phone) => form.setFieldValue("phone_number", phone)}
                                  />
                                )}
                              </Field>
                              <ErrorMessage
                                name="phone_number"
                                component="div"
                                className="error-message"
                              />
                            </div>
                            <div className="col-md-6">
                              <label htmlFor="inputPostalCode" className="small mb-1">
                                Postal Code <span className="required">*</span>
                              </label>
                              <Field
                                type="text"
                                name="postal_code"
                                className="form-control"
                                placeholder="Postal Code"
                              />
                              <ErrorMessage
                                name="postal_code"
                                component="div"
                                className="error-message"
                              />
                            </div>
                          </div>



                        </div>
                        <div className="modal-footer">
                          <button
                            type="reset"
                            className="btn org-btn-close"

                            onClick={() => {
                              setShowAddModal(false);
                              setFlagImg("");
                            }}
                          >
                            Close
                          </button>
                          <button type="submit" className="btn org-btn">
                            Add
                          </button>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>

            {/*  Attention Modal */}
            <div
              className={`modal fade ${toggleModal ? "show" : ""}`}
              style={{
                display: toggleModal ? "block" : "none",
                overflowY: "hidden",
              }}
            >
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header" style={{ background: 'linear-gradient(180deg, #56367F 0%, #3A5697 100%)', color: "white" }}>
                    <h5 className="modal-title" id="exampleModalLabel">
                      Attention
                    </h5>
                    <button
                      type="button"
                      className="btn-close"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setToggleModal(false);
                      }}

                      style={{ background: `url(${customCloseIcon}) no-repeat center center`, backgroundSize: 'cover', border: 'none', opacity: "1" }}
                    ></button>
                  </div>
                  <div className="modal-body">
                    <p>{`Are you sure you want to ${statusMsg === true ? "inactive" : "active"
                      } the organization?`}</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn close-btn"
                      style={{ backgroundColor: "#808080" }}
                      data-bs-dismiss="modal"
                      onClick={() => {
                        setToggleModal(false);
                      }}


                    >
                      Close
                    </button>
                    <button
                      type="button"
                      className="btn org-btn"
                      style={{ background: 'linear-gradient(90deg, #56367F 0%, #3A5697 100%)' }}
                      onClick={() => {
                        toggleStatus(toggleID);
                        setToggleModal(false);
                      }}

                    >
                      {statusMsg === true ? "Inactive" : "Active"}
                    </button>
                  </div>
                </div>
              </div>
            </div>

          </div>

        </Dashboard>
      </div>


      {/* modal to show otp to delete organization */}
      <div
        className={`modal fade ${showConfirmDeleteModal ? "show" : ""}`}
        style={{
          display: showConfirmDeleteModal ? "block" : "none",
          background: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            <form>
              <div
                className="modal-header pb-0  d-flex justify-content-end"
                style={{ background: "white", border: "none" }}
                onClick={() => {
                  setShowConfirmDeleteModal(false);
                  setOtpValues(["", "", "", "", "", ""]);
                }}
              >
                <CloseIcon className="btn-close-otp" />
              </div>
              <div className="modal-body pb-5 d-flex flex-column align-items-center gap-1">
                <h3 className=" fw-bold fs-2 m-0 mt-2">Deleting Organization</h3>
                <p className="text-secondary text-center mt-1">
                  Please confirm the OTP to completely delete the organization.
                </p>
                <form className="verify-otp-form d-flex gap-2">
                  {otpValues.map((value, index) => (
                    <input
                      key={index}
                      className="otp mb-sm-0 mb-3"
                      name="otp"
                      type="text"
                      value={value}
                      onChange={(e) => digitValidate(index, e.target.value)}
                      onKeyUp={() => tabChange(index + 1)}
                      maxLength={1}
                    />
                  ))}
                </form>
                <div className="otp-timer mt-4 ">
                  <p>OTP Validate: {formatTime()} </p>
                </div>
                <button
                  type="submit"
                  className={`login-form-button col-sm-7 col-10 text-capitalize ${isLoading ? "disabled" : ""}`}
                  onClick={(e) => handleSendOTPToDelete(e,orgRowID)}
                >
                  Confirm
                </button>
                <button
                  type="submit"
                  className={`login-form-button text-capitalize col-sm-7 mt-1 col-10 ${countdown > 0 ? "disabled" : ""
                    }`}
                  onClick={(e) => onDeleteOrganization(e, orgRowID)}
                >
                  Resend OTP
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

    </>
  );
}
