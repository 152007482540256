import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import adminLoginImg from "../../../assets/img/admin-login.png";
import { UserLogin } from "../../../context/AuthContext";
import { ADMIN_LOGIN_URL } from "../../../Auth_API";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import AppLoader from "../../Loader/AppLoader";
// import "./admin.css";
import { useTour } from "@reactour/tour";
import { Link } from "react-router-dom";

import onbLogo from "../../../assets/img/onbLogoPng.png";
import polygonTL from "../../../assets/img/PolygonTopLeft.svg";
import polygonBL from "../../../assets/img/PolygonBottomLeft.svg";
import polygonBR from "../../../assets/img/PolygonBottomRight.svg";
import cellsTR from "../../../assets/img/cellsTopRight.svg";
import loginSearchImg from "../../../assets/img/loginSearchImg.png";
import loginStatsImg from "../../../assets/img/loginStatsPic.png";

export default function AdminLogin() {
  const { setIsOpen } = useTour();
  let navigation = useNavigate();
  const [credentials, setCredentials] = useState({
    username: "",
    password: "",
  });
  const { username, password } = credentials;
  const {
    showPassword,
    setShowPassword,
    setIsLoading,
    isValidObjField,
    updateError,
    error,
    setError,
    setSuperToken,
    showToast,
    setIsErrorOpen,
    isLoading,
    setPreLoader,
  } = UserLogin();
  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const isValidEmail = (email) => {
    // Regular expression for basic email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  /*** Function to login a user ***/
  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      if (!isValidObjField(credentials)) {
        return updateError("Require all fields!", setError);
      }
      if (!isValidEmail(username)) {
        return updateError("Enter a valid email/username!", setError);
      }
      if (!password.trim() || password.length < 5) {
        return updateError("Password must be 5 character long!", setError);
      }

      // Convert email to lowercase
      const lowerCaseUsername = username.toLowerCase();

      setIsLoading(true);
      const response = await fetch(`${ADMIN_LOGIN_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: lowerCaseUsername,
          password: password,
        }),
      });
      if (response.ok) {
        const data = await response.json();
        setCredentials({ username: "", password: "" });
        localStorage.setItem("SuperToken", data.token);
        setSuperToken(data.token);
        showToast(`${data.message}`);
        navigation("/s_admin_dashboard");
        setPreLoader(true);
        setIsLoading(false);
        // setIsOpen(true);
      } else {
        const data = await response.json();
        showToast(`${data.message}`);
        setIsLoading(false);
        setIsErrorOpen(true);
      }
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Login failed 😢",
        text: "Failed to login!",
      });
      setIsLoading(false);
    }
  };

  return (
    <>
     <div className="login-main">
      <div className="login-page-container d-flex justify-content-center gap-2 col-12">
        <div className="login-page-left d-flex flex-column justify-content-center col-md-6 col-12 ">
          <div className="login-page-form mt-3 d-flex flex-column align-items-center">
            <div className="col-9 ps-4">
              <img
                src={onbLogo}
                alt="logo"
                className="login-form-logo"
              />
            </div>
            <div className="login-form-container col-9 mt-1 shadow-none">
              <div className="login-form-body">
                <h4 className="fw-bold text-capitalize">Admin Login</h4>
                <p className="m-0 p-0 text-secondary">
                  Please login the account with your details.
                </p>
                <hr />
                <div className="login-form-input mt-3 d-flex flex-column gap-2">
                  <label>Email Address (Required)</label>
                  <input
                    name="username"
                    value={username}
                    onChange={onChange}
                    margin="normal"
                    required
                    autoComplete="off"
                    type="text"
                    placeholder="Email"
                    className="col-12"
                  />
                  {error && !isValidEmail(username) && (
                    <span className="error-fields text-danger ms-2">Enter a valid email!</span>
                  )}
                </div>

                <div className="login-form-input mt-3 d-flex flex-column gap-2">
                  <label>Password</label>
                  <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      placeholder="Password"
                      className="form-control password"
                      name="password"
                      id="password"
                      value={password}
                      onChange={onChange}
                      margin="normal"
                      autoComplete="off"
                      required
                    />
                    <span className="input-group-text" onClick={handleTogglePassword}>
                      {showPassword ? (
                        <i
                          className="fa fa-eye text-secondary"
                          aria-hidden="true"
                          style={{ fontSize: "16px", cursor: "pointer" }}
                        ></i>
                      ) : (
                        <i
                          className="fa fa-eye-slash text-secondary"
                          aria-hidden="true"
                          style={{ fontSize: "16px", cursor: "pointer" }}
                        ></i>
                      )}
                    </span>
                  </div>
                  {error && (!password.trim() || password.length < 5) && (
                    <span className="error-fields text-danger ms-2">
                      Password must be at least 5 characters long!
                    </span>
                  )}
                </div>
                {/* <div className="d-flex align-items-center justify-content-start mt-2">
                  <Link
                    className="text-decoration-none mt-1"
                    style={{ color: "#56367f", fontSize: "16px", fontWeight: "600" }}
                    to="/forget_password"
                  >
                    Forgot password?
                  </Link>
                </div> */}

                <button
                  onClick={handleLogin}
                  className={`login-form-button col-12 mt-3 ${isLoading ? "disabled" : ""}`}
                  disabled={isLoading}
                >
                  Login
                </button>
              {isLoading ? <AppLoader /> : null}
              </div>
            </div>
          </div>
        </div>
        <div className="login-page-right bg-danger my-3 col-md-5 d-md-block d-none">
          <img src={loginSearchImg} alt="login-search-img" className="login-search-img" />
          <img src={polygonTL} alt="polygon" className="polygon-top-left" />
          <img src={cellsTR} alt="cells" className="cells-top-right" />
          <img src={polygonBL} alt="polygon" className="polygon-bottom-left" />
          <img src={polygonBR} alt="polygon" className="polygon-bottom-right" />
          <img src={loginStatsImg} alt="stats" className="login-stats" />
        </div>
      </div>
    </div>
    </>
  );
}
