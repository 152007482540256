import React from "react";
import Lottie from "lottie-react";
import "../../App.css"
const AppLoader = () => {

  return (
    <div className="app-loader-animation" style={{zIndex: 1000, backgroundColor: "#f5f5f5", borderRadius: "0"}}>
      <Lottie
        animationData={require("../../assets/animation/progress.json")}
        loop
        autoplay
        className="app-loader"
      /> 
    </div>
  );
};

export default AppLoader;
