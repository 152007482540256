import React, { useState } from "react";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import { UserLogin } from "../../../context/AuthContext";
import { ADMIN_FORGET_URL } from "../../../Auth_API";
import AppLoader from "../../Loader/AppLoader";
function FP_ADMIN_Screen1() {
  const [email_error, setEmail_Error] = useState(false);
  let navigation = useNavigate();
  const { isLoading, setIsLoading, error, showToast, setIsErrorOpen } =
    UserLogin();
  const [credentials, setCredentials] = useState({
    email: "",
  });
  const { email } = credentials;

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };
  const isValidEmail = (value) => {
    const regx = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    console.log(regx.test(value));
    return regx.test(value);
  };

  /* Function to reset password */
  const handleForgetPassword = async (e) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setEmail_Error(true);
      console.log(email_error);
      return;
    } else {
      setEmail_Error(false);
    }
    try {
      setIsLoading(true);
      const response = await fetch(`${ADMIN_FORGET_URL}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: email,
        }),
      });
      const data = await response.json();
      if (response.ok) {
        showToast("If you did not recieve an OTP, please check your spam!");
        setIsLoading(false);
        navigation("/admin_forget_otp_password", {
          state: { forget_pass_email: email },
          replace: true,
        });
      } else {
        showToast(`${data.error}`);
        setIsErrorOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Password reset OTP not sent!", error);
      showToast("Password reset OTP not sent!");
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="admin-container">
        <div className="container d-flex justify-content-center">
          <div
            className="card my-5"
            style={{ width: "35rem", borderRadius: "20px" }}
          >
            <div
              className="card-body"
              style={{ boxShadow: "0px 2px 20px 12px rgb(0, 0, 0, 0.1)", borderRadius: "15px" }}
            >
              <div className="reset-password-top">
                <h3 className="create-account">Reset your password</h3>
                <p className="signup-p">
                  Please enter your email address to search for your account.
                </p>
              </div>

              <form
                className="form-container"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div>
                  <label for="email" className="labels email-label">
                    Email
                  </label>
                  <TextField
                    placeholder="johndoe@gmail.com"
                    name="email"
                    value={email}
                    onChange={onChange}
                    margin="normal"
                    sx={{ width: "100%" }}
                    required
                    error={email_error && !isValidEmail(email)}
                    helperText={
                      email_error &&
                      !isValidEmail(email) &&
                      "Enter a valid email!"
                    }
                  />
                </div>
                <br />

                {/* Reset button */}
                <button
                  type="button"
                  className="reset-btn my-3"
                  style={{ background: "#979797" }}
                  onClick={() => {
                    navigation("/");
                  }}
                >
                  <span className="reset-text">Cancel</span>
                </button>
                <button
                  type="button"
                  className="reset-btn my-3"
                  style={{ float: "right" }}
                  onClick={handleForgetPassword}
                >
                  <span className="reset-text">Next</span>
                </button>
                {isLoading ? <AppLoader /> : null}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FP_ADMIN_Screen1;
