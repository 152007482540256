import React, { useEffect } from "react";
import { HiUserCircle } from "react-icons/hi";
import { useState } from "react";
import { UserLogin } from "../../../context/AuthContext";
import axios from "axios";
import { TOTAL_COUNT_CVS } from "../../../Auth_API";
import CountUp from "react-countup";
import CV from "../../../assets/img/total-cvs.png";

const SAdminLeft = () => {
  const [totalCVs, setTotalCV] = useState(0);
  const { superToken } = UserLogin();
  const countCV = async () => {
    await axios
      .get(`${TOTAL_COUNT_CVS}`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
      })
      .then((response) => {
        setTotalCV(response.data.total_cvs);
      });
  };
  useEffect(() => {
    if (superToken) {
      countCV();
    }
  }, [superToken]);
  return (
    <div className="cv-box shadow-sm d-flex align-items-center gap-3" >
      {/* <HiUserCircle className="cv-icon" /> */}
      <img src={CV} />
      <div>
        <p className="cv-title">Total CV's</p>
        <h5 className="content">
          <CountUp end={totalCVs} />
        </h5>
      </div>
    </div>
  );
};

export default SAdminLeft;
