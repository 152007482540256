import React, { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { SlCalender } from "react-icons/sl";
import { GrOrganization, GrUserAdmin } from "react-icons/gr";
import { UserLogin } from "../../../context/AuthContext";
import { TOTAL_COUNT_PI,TOTAL_COUNT_REPORTS, GET_ACTIVE_ADMINS } from "../../../Auth_API";
import axios from "axios";
import ActiveOrganization from "./ActiveOrganization";
import AdminAnalyticsChart from "./AdminAnalyticsChart";
import UserAnalyticsChart from "./UserAnalyticsChart";
import SAdminLeft from "./sAdminLeft";
import CountUp from "react-countup";
import assessment from "../../../assets/img/assessment-reports.png";
import PieOveriew from "./PieOveriew"; // Ensure this import is correct
import "../../../index.css"; // Ensure your CSS file is imported
import pireport from "../../../assets/img/pitests.png";


const Graph = () => {
  const { superToken } = UserLogin();
  let navigate = useNavigate();
  const [totalReports, setTotalReports] = useState(0);
  const [activeAdmins, setActiveAdmins] = useState({});
  const [tab, setTab] = useState("tab1");
  const [totalPI, setTotalPI] = useState(0);

  const countReports = async () => {
    await axios
      .get(`${TOTAL_COUNT_REPORTS}`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
      })
      .then((response) => {
        setTotalReports(response.data.total_oral_test_submissions);
      });
  };

    const countPI = async () => {
    await axios
      .get(`${TOTAL_COUNT_PI}`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
      })
      .then((response) => {
        setTotalPI(response.data.total_predictive_index);
      });
  };

  const getActiveAdmins = async () => {
    await axios
      .get(`${GET_ACTIVE_ADMINS}`, {
        headers: {
          Authorization: `Token ${superToken}`,
        },
      })
      .then((response) => {
        setActiveAdmins(response.data);
      });
  };

  useEffect(() => {
    if (superToken) {
      countReports();
      getActiveAdmins();
      countPI();
    }
  }, [superToken]);

  const handleClick = () => {
    window.scrollTo(0, 0);
  };

  return (
    <div className="s-transactions d-flex flex-column">
      <div className="left-super-info">
        <div className="analytics-space">

          {/* Analytics */}
          <div className="analytics-graph" >
            <div style={{ background: "#FFFFFF", borderRadius: "10px"}} className="analytics-graph-width">
              <div className="px-3 pt-3 d-flex flex-sm-row flex-column justify-content-between align-items-center ">
                <h5 className="fw-bold">Analytics</h5>
                <div className="d-flex align-items-center calender-space">
                  {/* <SlCalender className="fw-bold" />
                  <span className="fw-bold">Monthly</span> */}
                  <button
                    className="btn btn-analytics"
                    onClick={() => setTab("tab1")}
                  >
                    Users
                  </button>
                  <button
                    className="btn btn-analytics"
                    onClick={() => setTab("tab2")}
                  >
                    Admins
                  </button>
                </div>
              </div>
              {tab === "tab1" && <UserAnalyticsChart />}
              {tab === "tab2" && <AdminAnalyticsChart />}
            </div>
            
            <div className="d-flex mt-3 flex-lg-row flex-column ">
              <div className="col-12  ">
                <ActiveOrganization />
              </div>
            </div>
          </div>

          {/* total cv's and explore more div */}
          <div className="left-stats d-flex flex-column  justify-content-between mt-4 mt-lg-0">
            <div className="col-lg-3 col-12 ">
              {/* total cvs */}
              <div className="cv-box-margin ">
                <SAdminLeft />
              </div>
              {/*assessment reports  */}
              <div >
                <div className="cv-box-margin" >
                  <div className="cv-box shadow-sm d-flex gap-3 align-items-center" data-tour="2">
                    <img src={assessment} />
                    <div>
                      <p className="cv-title">Assessment Reports</p>
                      <h5 className="content">
                        <CountUp end={totalReports} />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
              {/* pi reports */}
              <div>
                <div className="cv-box-margin">
                  <div className="cv-box shadow-sm d-flex gap-3 align-items-center">
                    <img src={pireport} />
                    <div>
                      <p className="cv-title">PI's Reports</p>
                      <h5 className="content">
                      <CountUp end={totalPI} />
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="col-12 mt-lg-0 mt-0 ">
              <div className="footer-super-admin d-flex">
                <div className="footer-navigation mt-0">
                  <h4 className="admin-active-title-1">Explore More</h4>
                  <div className="navigation-card mt-md-4 mt-3 shadow-sm d-flex col-12">
                    <div className="card-header">
                      <div className="card-icon-background">
                        <GrUserAdmin className="card-icon" />
                      </div>
                    </div>
                    <div className="card-content">
                      <Link
                        to="/s_admin_dashboard/sub_admin"
                        onClick={handleClick}
                        className="card-title"
                      >
                        Admin
                      </Link>
                    </div>
                  </div>
                  
                  <div className="navigation-card mt-md-4 mt-4 shadow-sm d-flex col-12 " style={{ marginTop: "10px" }}>
                    <div className="card-header img-fluid">
                      <div className="card-icon-background">
                        <GrOrganization className="card-icon" />
                      </div>
                    </div>
                    <div className="card-content">
                      <NavLink
                        to={"/s_admin_dashboard/Organization"}
                        onClick={handleClick}
                        className="card-title"
                      >
                        Organization
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* analytics and overview div */}
        {/* New Section */}
        <div className="side-charts d-flex flex-column flex-md-row align-content-center gap-4 mt-4">
          <div
            className="p-3 shadow-sm rounded-3 col-md-6 col-sm-12 active-admin-chart"
            style={{ background: "#ffffff" }}
          >
            <h4 className="admin-active-title">Admins</h4>
            {Object.keys(activeAdmins).length !== 0 &&
              activeAdmins.map((item, index) => (
                <div className="mt-md-4 mt-3 s-admin-info d-flex" key={index}>
                  <span className="s-admin-logo">
                    {item.subadmin_email.charAt(0).toLocaleUpperCase()}
                  </span>
                  <div>
                    <p className="mb-0" style={{color:"#101828",fontSize:"16px",fontWeight:"400"}}>{item.subadmin_email}</p>
                    <p className="mb-0" style={{color:"#7B7B7B"}} >{item.organization_name}</p>
                  </div>
                </div>
              ))}
          </div>
          <div className="active-pie-gragh shadow-sm col-md-6 col-sm-12 analytical-overview-chart">
            <h4 className="admin-active-title">Analytics Overview</h4>
            <PieOveriew />
          </div>
        </div>
        {/* End of New Section */}
      </div>
    </div>
  );
};

export default Graph;
