export const AUTH_API_URL = "https://onboardiq.io/api/user_accounts";

export const INTERVIEW_API_URL = "http://192.168.18.29:5000/question";

export const REPORT_API_URL = "http://192.168.18.29:5000/report";

export const AUDIO_API_URL = "http://192.168.18.29:5000/test/english";

export const ADMIN_LOGIN_URL = "https://onboardiq.io/api/admin_login/admin-login/";

export const ADMIN_FORGET_URL = "https://onboardiq.io/api/admin_login/forgot-password/"

export const ADMIN_VERIFY_OTP = "https://onboardiq.io/api/admin_login/verify-otp/"

export const ADMIN_RESET_PASSWORD = "https://onboardiq.io/api/admin_login/reset-password/"

export const S_ADMIN_ALL_ORGANIZATION = "https://onboardiq.io/api/admin_login/organization/"
export const DEL_ADMIN_ORGANIZATION = "https://onboardiq.io/api/admin_login/organization/";

export const S_ADMIN_EDIT_ORGANIZATION = "https://onboardiq.io/api/admin_login/organization/";

export const S_ADMIN_ADD_ORGANIZATION = "https://onboardiq.io/api/admin_login/organization/create/";

export const ADMIN_TOGGLE_STATUS = "https://onboardiq.io/api/admin_login/organization/toggle_status/"

export const GET_ALL_USERS = "https://onboardiq.io/api/admin_login/sub_admins/";

export const ADD_SUB_ADMIN = "https://onboardiq.io/api/admin_login/add_sub_admin/";

export const DEL_SUB_ADMIN = "https://onboardiq.io/api/admin_login/sub_admins/delete/";

export const SUB_ADMIN_TOGGLE_STATUS = "https://onboardiq.io/api/admin_login/sub_admin/toggle_status/";

export const TOTAL_COUNT_SUB_ADMINS = "https://onboardiq.io/api/admin_login/total-active-subadmin/";

export const TOTAL_COUNT_USERS = "https://onboardiq.io/api/admin_login/total-users/";

export const TOTAL_COUNT_ORGANIZATION = "https://onboardiq.io/api/admin_login/total-organizations/";

export const TOTAL_COUNT_ORAL_ASSESSMENT = "https://onboardiq.io/api/admin_login/total-oral-test-submissions/";

export const TOTAL_COUNT_CVS = "https://onboardiq.io/api/admin_login/total-cvs/";

export const TOTAL_COUNT_PI = "https://onboardiq.io/api/admin_login/total-predictive-index/"

export const TOTAL_COUNT_REPORTS = "https://onboardiq.io/api/admin_login/total-oral-test-submissions/"

export const GET_ACTIVE_ORGANIZATION = "https://onboardiq.io/api/admin_login/top-organizations/";

export const GET_ACTIVE_ADMINS = "https://onboardiq.io/api/admin_login/top-active-admins/"

export const GET_ADMIN_USER_PERCENTAGE = "https://onboardiq.io/api/admin_login/user-subadmin-percentages/"

export const SUPER_ADMIN_URL = "https://onboardiq.io/api/admin_accounts"
