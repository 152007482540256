import React from "react";
import { Route, Routes } from "react-router-dom";
import { UserLogin } from "../context/AuthContext";
import FP_ADMIN_Screen1 from "../components/AuthScreens/AdminForgetPasssword/FP_ADMIN_Screen1";
import FP_ADMIN_Screen3 from "../components/AuthScreens/AdminForgetPasssword/FP_Screen3";
import FP_ADMIN_Screen2 from "../components/AuthScreens/AdminForgetPasssword/FP_ADMIN_Screen2";
import AdminLogin from "../components/AdminView/AdminLogin/AdminLogin";
import AdminDashboard from "../components/AdminView/AdminDashboard/main";
import Organization from "../components/AdminView/AdminDashboard/Organization/Organization";
import SubAdmin from "../components/AdminView/AdminDashboard/User/subAdmin";
import ErrorPage from "../components/ErrorPage/ErrorPage";
import { Navigate } from "react-router-dom";
import SkimMain from "../components/CVSkimming/SkimMain";

function SuperAdminStack() {
  const { superToken } = UserLogin();

  return (
    <div style={{ fontFamily: "Roboto, sans-serif" }}>
      <>
        <Routes>
          <Route path="/" element={superToken ? <AdminDashboard /> : <AdminLogin />} />
          <Route path="/admin_forget_password" element={<FP_ADMIN_Screen1 />} />
          <Route path="/admin_forget_otp_password" element={<FP_ADMIN_Screen2 />} />
          <Route path="/admin_reset_password" element={<FP_ADMIN_Screen3 />} />
          <Route path="/s_admin_dashboard" element={<AdminDashboard />} />
          <Route path="/s_admin_dashboard/Organization" element={<Organization />} />
          <Route path="/s_admin_dashboard/sub_admin" element={<SubAdmin />} />
          <Route path="/s_admin_dashboard/cv_skim" element={<SkimMain />} />
          <Route path="/errorpage" element={<ErrorPage />} />
          <Route path="*" element={superToken && <Navigate to="/errorpage" />} />
        </Routes>
      </>
    </div>
  );
}

export default SuperAdminStack;
