import React from 'react';
import { UserLogin } from '../../../context/AuthContext';

function SkimFields() {
    const { cvSkimdescription, setcvSkimDescription, selectedDescFiles } = UserLogin();

    const handleDescriptionChange = (event) => {
        setcvSkimDescription(event.target.value);
    };

    return (
        <div className='mt-3'>
            <div>
                <label className='skim-labels'>Description</label>
                <input
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="Enter job description or keywords with separation for checking similarity"
                    className='skim-fields'
                    value={cvSkimdescription}
                    onChange={handleDescriptionChange}
                    readOnly={!!selectedDescFiles}
                />
            </div>
        </div>
    );
}
export default SkimFields;
