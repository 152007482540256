import React, { useState } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { UserLogin } from "../../../context/AuthContext";
import { ADMIN_RESET_PASSWORD } from "../../../Auth_API";
import AppLoader from "../../Loader/AppLoader";
function FP_ADMIN_Screen3() {
  let navigation = useNavigate();
  const location = useLocation();
  const { reset_pass_email, otp_admin } = location.state || {};
  const {
    isLoading,
    setIsLoading,
    error,
    setError,
    updateError,
    isValidObjField,
    showToast,
    setIsErrorOpen,
    showPassword,
    setShowPassword,
  } = UserLogin();

  const [credentials, setCredentials] = useState({
    new_password: "",
  });
  const { new_password } = credentials;

  const onChange = (e) => {
    setCredentials({ ...credentials, [e.target.name]: e.target.value });
  };

  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  /* Function to reset password */
  const handleResetPassword = async (e) => {
    e.preventDefault();
    try {
      if (!isValidObjField(credentials)) {
        return updateError("Require all fields!", setError);
      }
      if (!new_password.trim() || new_password.length < 5) {
        return updateError("Password must be 5 character long!", setError);
      }

      setIsLoading(true);
      const response = await fetch(`${ADMIN_RESET_PASSWORD}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          username: reset_pass_email,
          otp: otp_admin,
          new_password: new_password,
        }),
      });
      if (response.ok) {
        showToast("Your Password changed. Please Login to continue!");
        navigation("/");
        setIsLoading(false);
      } else {
        showToast("Failed to reset password!");
        setIsErrorOpen(true);
        setIsLoading(false);
      }
    } catch (error) {
      showToast("Failed to reset password!");
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="admin-container">
        <div className="container d-flex justify-content-center">
          <div
            className="card my-5"
            style={{ width: "35rem", borderRadius: "20px" }}
          >
            <div
              className="card-body"
              style={{
                boxShadow: "0px 2px 20px 12px rgb(0, 0, 0, 0.1)",
                position: "relative",
              }}
            >
              <div className="reset-password-top">
                <h3 className="create-account">Reset your password</h3>
                <p className="signup-p">Please enter your new Password</p>
              </div>

              <form className="form-container" autoComplete="off">
                <div>
                  <label for="password" className="labels">
                    Password
                  </label>
                  <TextField
                    placeholder="New Password"
                    autoComplete="off"
                    type={showPassword ? "text" : "password"}
                    name="new_password"
                    value={new_password}
                    onChange={onChange}
                    margin="normal"
                    sx={{ width: "100%" }}
                    required
                    error={
                      error && (!new_password.trim() || new_password.length < 5)
                    }
                    helperText={
                      error &&
                      (!new_password.trim() || new_password.length < 5) &&
                      "Password must be at least 5 characters long!"
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleTogglePassword} edge="end">
                            {/* Eye icon */}
                            {showPassword ? (
                              <i
                                className="fa fa-eye"
                                aria-hidden="true"
                                style={{ fontSize: "16px" }}
                              ></i>
                            ) : (
                              <i
                                className="fa fa-eye-slash"
                                aria-hidden="true"
                                style={{ fontSize: "16px" }}
                              ></i>
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </div>
                <br />
                {/* Password set button */}
                <div
                  className="d-flex flex-md-row flex-column justify-content-center"
                  style={{ columnGap: "2rem" }}
                >
                  <button
                    className="reset-btn my-3"
                    style={{ background: "#979797" }}
                    onClick={() => {
                      navigation("/");
                    }}
                  >
                    <span className="reset-text">Cancel</span>
                  </button>
                  <button
                    className="reset-btn my-3"
                    onClick={handleResetPassword}
                  >
                    <span className="reset-text">Reset password</span>
                  </button>
                </div>
              </form>
              {isLoading ? <AppLoader /> : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default FP_ADMIN_Screen3;
