import React, { useState } from 'react';
import cv_cloud from "../../../assets/img/cv-skimming.png";
import { UserLogin } from '../../../context/AuthContext';
import { motion } from 'framer-motion';

function UploadCV_Desc() {
    const {
        selectedFiles,
        setSelectedFiles,
        selectedDescFiles,
        setSelectedDescFiles,
        cvSkimdescription,
        fileInputRef,
        descInputRef,
        zipInputRef,
        selectedZipFolder,
        setSelectedZipFolder,
    } = UserLogin();
    const [dragOver, setDragOver] = useState(false);
    const [dragZipOver, setDragZipOver] = useState(false);
    const [dragDescOver, setDragDescOver] = useState(false);
    const [selectedButton, setSelectedButton] = useState('folder');
    const handleFolderButtonClick = () => {
        setSelectedButton('folder');
    };

    const handleFilesButtonClick = () => {
        setSelectedButton('files');
    };

    // Function of pdf uploads
    const handleBrowseClick = () => {
        fileInputRef.current.click();
    };

    const handleDragEnter = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        setDragOver(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setDragOver(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setDragOver(false);
        const newFiles = Array.from(e.dataTransfer.files);
        if (newFiles?.length) {
            setSelectedFiles(prevFiles => [...prevFiles, ...newFiles]);
        }
    };

    const handleRemoveFile = (fileName, type) => {
        setSelectedFiles(prevFiles => prevFiles.filter(file => file.name !== fileName));
        if (fileInputRef.current) fileInputRef.current.value = '';
    };

    const handleFilesChange = (e) => {
        const files = Array.from(e.target.files);
        if (files?.length) {
            setSelectedFiles(prevFiles => [...prevFiles, ...files]);
        }
    };

    // Function of zip and rar uploads
    const handleZipBrowseClick = () => {
        zipInputRef.current.click();
    };

    const handleZipDragEnter = (e) => {
        e.preventDefault();
        setDragZipOver(true);
    };

    const handleZipDragOver = (e) => {
        e.preventDefault();
        setDragZipOver(true);
    };

    const handleZipDragLeave = (e) => {
        e.preventDefault();
        setDragZipOver(false);
    };

    const handleZipRemoveFile = () => {
        setSelectedZipFolder(null);
        if (zipInputRef.current) zipInputRef.current.value = '';
    };

    const handleZipDrop = (e) => {
        e.preventDefault();
        setDragZipOver(false);
        const file = e.dataTransfer.files[0];
        if (file && (file.type === 'application/zip')) {
            setSelectedZipFolder(file);
        }
    };

    const handleZipFilesChange = (e) => {
        const file = e.target.files[0];
        const validTypes = [
            'application/zip',
            'application/x-zip-compressed',
            'application/x-compressed'
        ];
        if (file && validTypes.includes(file.type)) {
            setSelectedZipFolder(file);
        } else {
            console.log('File type not valid:', file.type);
        }
    };

    // Dsecription files selection
    const handleDescBrowseClick = () => {
        descInputRef.current.click();
    };

    const handleDescDragEnter = (e) => {
        e.preventDefault();
        setDragDescOver(true);
    };

    const handleDescDragOver = (e) => {
        e.preventDefault();
        setDragDescOver(true);
    };

    const handleDescDragLeave = (e) => {
        e.preventDefault();
        setDragDescOver(false);
    };

    const handleDescDrop = (e) => {
        e.preventDefault();
        setDragDescOver(false);
        const file = e.dataTransfer.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedDescFiles(file);
        }
    };

    const handleDescRemoveFile = () => {
        setSelectedDescFiles(null);
        if (descInputRef.current) descInputRef.current.value = '';
    };

    const handleDescFilesChange = (e) => {
        const file = e.target.files[0];
        if (file && file.type === 'application/pdf') {
            setSelectedDescFiles(file);
        }
    };

    const variants = {
        initial: { opacity: 0, x: -50 },
        animate: { opacity: 1, x: 0 },
        exit: { opacity: 0, x: 50 },
    };

    return (
        <>
            <div className="container-fluid">
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <p className="skim-labels cv-label1">Upload CV</p>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 '>
                        <p>
                            <span className="skim-labels cv-label2">Upload Description</span>
                            <span className='mx-2 cv-label2'>Please upload the description you'd like to skim CVs with in PDF format.</span>
                        </p>
                    </div>
                </div>
                <div className={`row justify-content-center cv-skim-container-row`} >
                    <div className='toggle-buttons-cv'>
                        <button
                            onClick={handleFolderButtonClick}
                            style={{
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px",
                                background: selectedButton === 'folder'
                                    ? 'linear-gradient(90deg, #56367F 0%, #3A5697 100%)'
                                    : 'transparent',
                                color: selectedButton === 'folder' ? '#fff' : '#000',
                                border: selectedButton === 'folder' ? "none" : "2px solid #A7A7A7"
                            }}
                        >
                            Upload Folder
                        </button>

                        <button
                            onClick={handleFilesButtonClick}
                            className='upload-files-btn'
                            style={{
                                background: selectedButton === 'files'
                                    ? 'linear-gradient(90deg, #56367F 0%, #3A5697 100%)'
                                    : 'transparent',
                                color: selectedButton === 'files' ? '#fff' : '#000',
                                border: selectedButton === 'files' ? "none" : "2px solid #A7A7A7"
                            }}
                        >
                            Upload Files
                        </button>
                    </div>

                    {selectedButton === "folder" ? (
                        <motion.div
                            key="folder"
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.5 }}
                            className={`col-lg-6 col-md-12 col-sm-12 cv-skim-card px-xl-3 px-lg-3 px-md-3 px-2 py-3 ${dragZipOver ? 'drag-over' : ''}`}
                            onDragEnter={handleZipDragEnter}
                            onDragOver={handleZipDragOver}
                            onDragLeave={handleZipDragLeave}
                            onDrop={handleZipDrop}
                        >
                            <div className="p-4 pt-md-5 pt-3 cv-skim-inside">
                                {selectedZipFolder ? (
                                    <div className='selected-cv-skim'>
                                        <div className='mt-3' style={{ cursor: 'pointer', maxHeight: '150px', overflowY: 'auto' }}>
                                            <div className="file-item">
                                                <span>{selectedZipFolder.name}</span>
                                                <span className="remove-file" onClick={handleZipRemoveFile}>X</span>
                                            </div>
                                        </div>
                                        <br />
                                        <span className="new-line pb-1" onClick={handleZipBrowseClick} style={{ marginTop: "15%" }}>
                                            or
                                            <span style={{ color: "#66b855", cursor: "pointer", fontWeight: "600" }} className='mx-1'>
                                                Drag More Files
                                            </span>
                                        </span>
                                    </div>
                                ) : (
                                    <div className='cv-skim-last' onClick={handleZipBrowseClick}>
                                        <img src={cv_cloud} alt='cv cloud image' className='cv-skim-cloud-img' />
                                        <p className="content mt-3" style={{ fontSize: "16px" }}>
                                            Drop files here or click to upload.
                                        </p>
                                        <p className='cv-small-text mt-2'>Upload CV in .zip folder</p>
                                    </div>
                                )}
                            </div>
                            <input
                                type="file"
                                ref={zipInputRef}
                                accept=".zip, application/zip, application/x-zip-compressed"
                                style={{ display: 'none' }}
                                onChange={handleZipFilesChange}
                            />
                        </motion.div>
                    ) : (
                        <motion.div
                            key="files"
                            variants={variants}
                            initial="initial"
                            animate="animate"
                            exit="exit"
                            transition={{ duration: 0.5 }}
                            className={`col-lg-6 col-md-12 col-sm-12 cv-skim-card px-xl-3 px-lg-3 px-md-3 px-2 py-3 ${dragOver ? 'drag-over' : ''}`}
                            onDragEnter={handleDragEnter}
                            onDragOver={handleDragOver}
                            onDragLeave={handleDragLeave}
                            onDrop={handleDrop}
                        >
                            <div className="p-4 pt-md-5 pt-3 cv-skim-inside">
                                {selectedFiles?.length > 0 ? (
                                    <div className='selected-cv-skim'>
                                        <div className='mt-3' style={{ cursor: 'pointer', maxHeight: '150px', overflowY: 'auto' }}>
                                            {selectedFiles.map((file, index) => (
                                                <div key={index} className="file-item">
                                                    <span>{file.name}</span>
                                                    <span className="remove-file" onClick={() => handleRemoveFile(file.name)}>X</span>
                                                </div>
                                            ))}
                                        </div>
                                        <br />
                                        <span className="new-line pb-1" onClick={handleBrowseClick} style={{ marginTop: "15%" }}>
                                            or
                                            <span style={{ color: "#66b855", cursor: "pointer", fontWeight: "600" }} className='mx-1'>
                                                Drag More Files
                                            </span>
                                        </span>
                                    </div>
                                ) : (
                                    <div className='cv-skim-last' onClick={handleBrowseClick}>
                                        <img src={cv_cloud} alt='cv cloud image' className='cv-skim-cloud-img' />
                                        <p className="content mt-3" style={{ fontSize: "16px" }}>
                                            Drop files here or click to upload.
                                        </p>
                                        <p className='cv-small-text mt-2'>Upload multiple CV's in pdf format</p>
                                    </div>
                                )}
                            </div>
                            <input
                                type="file"
                                ref={fileInputRef}
                                accept=".pdf"
                                multiple
                                style={{ display: 'none' }}
                                onChange={handleFilesChange}
                            />
                        </motion.div>
                    )}

                    <p className="skim-labels cv-label3 mt-3">Upload Description</p>
                    <span className='mx-2 cv-label3'>Please upload the description you'd like to skim CVs with in PDF format.</span>
                    <div className={`col-lg-6 col-md-12 col-sm-12 cv-skim-card px-xl-3 px-lg-3 px-md-3 px-2 py-3 ${dragDescOver ? 'drag-over' : ''}`}
                        onDragEnter={handleDescDragEnter}
                        onDragOver={handleDescDragOver}
                        onDragLeave={handleDescDragLeave}
                        onDrop={handleDescDrop}
                    >
                        <div className="p-4 pt-md-5 pt-3 cv-skim-inside">
                            {selectedDescFiles ? (
                                <div className='selected-cv-skim'>
                                    <div className='mt-3'
                                        style={{
                                            cursor: 'pointer',
                                            maxHeight: '150px',
                                            overflowY: 'auto'
                                        }}
                                    >
                                        <div className="file-item">
                                            <span>{selectedDescFiles.name}</span>
                                            <span className="remove-file" onClick={handleDescRemoveFile}>X</span>
                                        </div>
                                    </div>
                                    <br />
                                    <span className="new-line pb-1" onClick={handleDescBrowseClick}>
                                        or
                                        <span
                                            style={{
                                                color: "#66b855",
                                                cursor: "pointer",
                                                fontWeight: "600"
                                            }}
                                            className='mx-1'>
                                            Drag Another File
                                        </span>
                                    </span>
                                </div>
                            ) : (
                                <div className='cv-skim-last' onClick={handleDescBrowseClick}>
                                    <img src={cv_cloud} alt='cv cloud image' className='cv-skim-cloud-img' />
                                    <p className="content mt-3" style={{ fontSize: "16px" }}>
                                        Drop files here or click to upload.
                                    </p>
                                    <p className='cv-small-text mt-2'>Upload Description in PDF</p>
                                </div>
                            )}
                        </div>
                        <input
                            type="file"
                            ref={descInputRef}
                            accept=".pdf"
                            style={{ display: 'none' }}
                            onChange={handleDescFilesChange}
                            disabled={cvSkimdescription.trim()?.length > 0}
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

export default UploadCV_Desc;
