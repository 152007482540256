import React from "react";
import "./error.css"
import { useNavigate } from "react-router";
import pic404 from "../../assets/pic404.png";
function ErrorPage() {
    const navigation = useNavigate()
  return (
    <>
      <div className="signup">
        <div
          className="signup-container"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "100vh",
          }}
        >
          <div className="error-card card py-5">
            <div className="card-body">
              <div className="resend-email-top">
                <img src={pic404} alt="error-img" className="img-fluid"/>
              </div>
              <div className="resend-email-second">
                <p className="account-created-first">Oops, we should have seen that coming!</p>
                <p className="error-desc-text-one" style={{ marginTop: "-10px" }}>
                Our programmers are currently in the middle of a coding frenzy, that may have caused a few glitches along the way. 
                </p>
                <p className="resend-email-description">
                  <span className="error-desc-text-two">
                  Please excuse the mess! We'll have things sorted out in no time.
                  </span>
                  <p className="error-desc-text-two">
                  Until then, why not explore our homepage?
                    </p>
                </p>
              </div>
                <button type="button" className="error-btn" onClick={()=>navigation("/")}>
                  No hard feelings, take me
                </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default ErrorPage;