import React, { createContext, useContext, useState, useEffect, useRef } from "react";
import { Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router";
const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [stickyNav, setstickyNav] = useState(false);
  const [toTop, settoTop] = useState(false);
  const [active, setActive] = useState(0);
  const [activeCV, setActiveCV] = useState(0);
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [toastMessage, setToastMessage] = useState("");
  const [isToastOpen, setIsToastOpen] = useState(false);
  const [isErrorOpen, setIsErrorOpen] = useState(false);
  const [timerValue, setTimerValue] = useState(0);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [reportData, setReportData] = useState([]);
  const [interviewName, setInterviewName] = useState("");
  const storedToken = localStorage.getItem("token");
  const [token, setToken] = useState(storedToken);
  const storedUserData = localStorage.getItem("userData");
  const [preLoader, setPreLoader] = useState(false);

  let initialUserData;
  try {
    initialUserData = storedUserData ? JSON.parse(storedUserData) : {};
  } catch (error) {
    console.error("Error parsing storedUserData:", error);
    initialUserData = {};
  }

  const [userData, setUserData] = useState(initialUserData);

  const [language, setLanguage] = useState("");
  const [fname, setFname] = useState("");
  const [mname, setMname] = useState("");
  const [lname, setLname] = useState("");
  const [email, setEmail] = useState("");
  const [pi_id, setpi_id] = useState("");
  const [age, setAge] = useState("");
  const [gender, setGender] = useState("");
  const [race, setRace] = useState("");
  const [education, setEducation] = useState("");
  const [radio11, setRadio11] = useState("");
  const [radio12, setRadio12] = useState("");
  const [radio13, setRadio13] = useState("");
  const [checkbox1_pi, setCheckbox1_pi] = useState([]);
  const [checkbox2_pi, setCheckbox2_pi] = useState("");
  const [getSuperToken] = useState(localStorage.getItem("SuperToken"));
  const [superToken, setSuperToken] = useState(getSuperToken);
  const navigation = useNavigate();
  /***** Functions to format timer for interview ******/
  useEffect(() => {
    let intervalId;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimerValue((prevValue) => {
          if (prevValue >= 3600) {
            setIsTimerRunning(false);
            return 3600;
          }
          return prevValue + 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning]);

  const formatTimer = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = seconds % 60;
    const formattedMins = mins < 10 ? `0${mins}` : mins;
    const formattedSecs = secs < 10 ? `0${secs}` : secs;
    return `${formattedMins}:${formattedSecs}`;
  };

  /***** Validation Checks ******/
  const isValidObjField = (obj) => {
    return Object.values(obj).every((value) => value.trim());
  };
  const updateError = (error, stateUpdater) => {
    stateUpdater(error);
    setTimeout(() => {
      stateUpdater("");
    }, 2500);
  };

  /***** Showing Toast/Alert ******/
  const showToast = (message) => {
    setToastMessage(message);
    setIsToastOpen(true);
  };

  const handleToastClose = () => {
    setIsToastOpen(false);
  };

  const handleErrorClose = () => {
    setIsErrorOpen(false);
  };

  /* Function to capitalize first letter of name */
  const capitalizeFirst = (str) => {
    if (typeof str === "string") {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }
    return str;
  };

  /* Descripiton for each Levels */
  const levelDescriptions = {
    beginner:
      "At the beginner level, individuals are starting to grasp basic conversational phrases. They can exchange simple greetings and engage in uncomplicated discussions about topics like the weather, daily routines, and personal hobbies. While they may stumble occasionally, they are eager to learn and improve their ability to participate in everyday chit-chat with friends and acquaintances.",

    elementary:
      "Elementary-level practitioners are gaining confidence in their conversational skills. They can comfortably initiate and sustain dialogues on familiar subjects, including personal interests, favorite foods, and leisure activities. While they might occasionally seek help with complex vocabulary, they're well on their way to becoming adept at handling light-hearted exchanges and social interactions.",

    intermediate:
      "Individuals at the intermediate level of exhibit proficiency in engaging discussions. They can confidently express opinions, share experiences, and discuss a broad array of topics, such as travel destinations, recent movies, and upcoming plans. Their ability to navigate conversations fluidly and respond thoughtfully makes them valuable contributors to social gatherings and casual conversations.",

    "upper-intermediate":
      "At the upper-intermediate level, enthusiasts display finesse in steering conversations. They can delve into deeper subjects, such as cultural trends, personal goals, and societal issues, while maintaining a comfortable and engaging atmosphere. Their conversational prowess allows them to connect with others on a meaningful level, making them sought-after conversationalists.",

    advanced:
      "Advanced practitioners are virtuosos in the art of conversation. They effortlessly navigate intricate topics, including philosophy, art, and global affairs, while weaving in personal anecdotes and insightful perspectives. Their eloquence and charm make them captivating interlocutors who can turn any exchange into a memorable and enriching experience.",
  };

  /* Display Highest Level in Polygon */
  const levelCodes = {
    beginner: "A1",
    elementary: "A2",
    intermediate: "B1",
    "upper-intermediate": "B2",
    advanced: "C1",
    proficiency: "C2",
  };

  /***** Get and Store CSRF Token in localStorage ******/
  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }, [token]);

  /***** Protected Routing ******/
  // useEffect(() => {
  //   const token = localStorage.getItem("SuperToken");
  //   if (token) {
  //     navigate("/s_admin_dashboard");
  //   }
  // }, [navigate]);
  // useEffect(() => {
  //   if (!SubToken) {
  //     if (window.location.pathname.startsWith("/sub_admin_forget_password") || window.location.pathname.startsWith("/sub_admin_forget_otp_password") || window.location.pathname.startsWith("/sub_admin_reset_password")) {
  //       navigation(window.location.pathname);
  //     } else {
  //       navigation('/');
  //     }
  //   }
  // }, [SubToken, navigation]);
  useEffect(() => {
    if (!superToken) {
      if (
        window.location.pathname.startsWith("/admin_forget_password") ||
        window.location.pathname.startsWith("/admin_forget_otp_password") ||
        window.location.pathname.startsWith("/admin_reset_password")
      ) {
        navigation(window.location.pathname);
      } else {
        navigation("/");
      }
    }
  }, [superToken, navigation]);


  const countryCodes = {
    Afghanistan: "AF",
    Albania: "AL",
    Algeria: "DZ",
    Andorra: "AD",
    Angola: "AO",
    "Antigua & Deps": "AG",
    Argentina: "AR",
    Armenia: "AM",
    Australia: "AU",
    Austria: "AT",
    Azerbaijan: "AZ",
    Bahamas: "BS",
    Bahrain: "BH",
    Bangladesh: "BD",
    Barbados: "BB",
    Belarus: "BY",
    Belgium: "BE",
    Belize: "BZ",
    Benin: "BJ",
    Bhutan: "BT",
    Bolivia: "BO",
    "Bosnia Herzegovina": "BA",
    Botswana: "BW",
    Brazil: "BR",
    Brunei: "BN",
    Bulgaria: "BG",
    Burkina: "BF",
    Burundi: "BI",
    Curaçao: "CW",
    Cambodia: "KH",
    Cameroon: "CM",
    Canada: "CA",
    "Cape Verde": "CV",
    "Central African Rep": "CF",
    Chad: "TD",
    Chile: "CL",
    China: "CN",
    Colombia: "CO",
    Comoros: "KM",
    Congo: "CG",
    "Congo {Democratic Rep}": "CD",
    "Costa Rica": "CR",
    Croatia: "HR",
    Cuba: "CU",
    Cyprus: "CY",
    "Czech Republic": "CZ",
    Denmark: "DK",
    Djibouti: "DJ",
    Dominica: "DM",
    "Dominican Republic": "DO",
    "East Timor": "TL",
    Ecuador: "EC",
    Egypt: "EG",
    "El Salvador": "SV",
    "Equatorial Guinea": "GQ",
    Eritrea: "ER",
    Estonia: "EE",
    Ethiopia: "ET",
    Fiji: "FJ",
    Finland: "FI",
    France: "FR",
    Gabon: "GA",
    Gambia: "GM",
    Georgia: "GE",
    Germany: "DE",
    Ghana: "GH",
    Greece: "GR",
    Grenada: "GD",
    Guatemala: "GT",
    Guinea: "GN",
    "Guinea-Bissau": "GW",
    Guyana: "GY",
    "Hong Kong": "HK",
    Haiti: "HT",
    Honduras: "HN",
    Hungary: "HU",
    Iceland: "IS",
    India: "IN",
    Indonesia: "ID",
    Iran: "IR",
    Iraq: "IQ",
    "Ireland {Republic}": "IE",
    Israel: "IL",
    Italy: "IT",
    "Ivory Coast": "CI",
    Jamaica: "JM",
    Japan: "JP",
    Jordan: "JO",
    Kazakhstan: "KZ",
    Kenya: "KE",
    Kiribati: "KI",
    "Korea North": "KP",
    "Korea South": "KR",
    Kosovo: "XK",
    Kuwait: "KW",
    Kyrgyzstan: "KG",
    Laos: "LA",
    Latvia: "LV",
    Lebanon: "LB",
    Lesotho: "LS",
    Liberia: "LR",
    Libya: "LY",
    Liechtenstein: "LI",
    Lithuania: "LT",
    Luxembourg: "LU",
    Macedonia: "MK",
    Madagascar: "MG",
    Malawi: "MW",
    Malaysia: "MY",
    Maldives: "MV",
    Mali: "ML",
    Malta: "MT",
    "Marshall Islands": "MH",
    Mauritania: "MR",
    Mauritius: "MU",
    Mexico: "MX",
    Micronesia: "FM",
    Moldova: "MD",
    Monaco: "MC",
    Mongolia: "MN",
    Montenegro: "ME",
    Morocco: "MA",
    Mozambique: "MZ",
    "Myanmar, {Burma}": "MM",
    Namibia: "NA",
    Nauru: "NR",
    Nepal: "NP",
    Netherlands: "NL",
    "New Zealand": "NZ",
    Nicaragua: "NI",
    Niger: "NE",
    Nigeria: "NG",
    Norway: "NO",
    Oman: "OM",
    Pakistan: "PK",
    Palau: "PW",
    Panama: "PA",
    "Papua New Guinea": "PG",
    "Puerto Rico": "PR",
    Paraguay: "PY",
    Peru: "PE",
    Philippines: "PH",
    Poland: "PL",
    Portugal: "PT",
    Qatar: "QA",
    Romania: "RO",
    "Russian Federation": "RU",
    Rwanda: "RW",
    "St Kitts & Nevis": "KN",
    "St Lucia": "LC",
    "Saint Vincent & the Grenadines": "VC",
    Samoa: "WS",
    "San Marino": "SM",
    "Sao Tome & Principe": "ST",
    "Saudi Arabia": "SA",
    Senegal: "SN",
    Serbia: "RS",
    Seychelles: "SC",
    "Sierra Leone": "SL",
    Singapore: "SG",
    Slovakia: "SK",
    Slovenia: "SI",
    "Solomon Islands": "SB",
    Somalia: "SO",
    "South Africa": "ZA",
    "South Sudan": "SS",
    Spain: "ES",
    "Sri Lanka": "LK",
    Sudan: "SD",
    Suriname: "SR",
    Swaziland: "SZ",
    Sweden: "SE",
    Switzerland: "CH",
    Syria: "SY",
    Taiwan: "TW",
    Tajikistan: "TJ",
    Tanzania: "TZ",
    Thailand: "TH",
    Togo: "TG",
    Tonga: "TO",
    "Trinidad & Tobago": "TT",
    Tunisia: "TN",
    Turkey: "TR",
    Turkmenistan: "TM",
    Tuvalu: "TV",
    Uganda: "UG",
    Ukraine: "UA",
    "United Arab Emirates": "AE",
    "United Kingdom": "GB",
    "United States": "US",
    Uruguay: "UY",
    Uzbekistan: "UZ",
    Vanuatu: "VU",
    "Vatican City": "VA",
    Venezuela: "VE",
    Vietnam: "VN",
    Yemen: "YE",
    Zambia: "ZM",
    Zimbabwe: "ZW",
  };

  const [run, setRun] = useState(false);

  const [steps, setSteps] = useState([

    {
      target: ".tour-step-one",
      title: "Record Button",
      content: "Click the record button to begin your interview recording.",
      placement: "top",
    },
    {
      target: ".tour-step-two",
      title: "Question Description",
      content: "Use the question description for a quick overview of each question's topic.",
      placement: "top",
    },
    {
      target: ".tour-step-three",
      title: "Interview Time",
      content: "Keep track of time with the timer to manage interview duration.",
      placement: "top",
    },
    {
      target: ".tour-step-four",
      title: "Number of Questions",
      content: "Number questions for easy reference as you provide answers.",
      placement: "top",
    },
  ]);

  const [isTourOpen, setIstourOpen] = useState(false);
  const [isCVSkimLoading, setIsCVSkimLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [cvSkimdescription, setcvSkimDescription] = useState("");
  const [selectedZipFolder, setSelectedZipFolder] = useState("");
  const [selectedDescFiles, setSelectedDescFiles] = useState("");
  const [skimResponse, setSkimResponse] = useState([]);
  const fileInputRef = useRef(null);
  const descInputRef = useRef(null);
  const zipInputRef = useRef(null);

  return (
    <AuthContext.Provider
      value={{
        fileInputRef, 
        descInputRef,
        zipInputRef,
        selectedFiles, 
        setSelectedFiles,
        selectedDescFiles, 
        setSelectedDescFiles,
        selectedZipFolder, 
        setSelectedZipFolder,

        cvSkimdescription, 
        setcvSkimDescription,
        run, 
        setRun, 
        steps, 
        setSteps,
        isTourOpen, 
        setIstourOpen,
        isCVSkimLoading, 
        setIsCVSkimLoading,
        skimResponse, 
        setSkimResponse,
        userData,
        setUserData,
        token,
        setToken,
        superToken,
        isValidObjField,
        updateError,
        showToast,
        error,
        setError,
        setIsErrorOpen,
        capitalizeFirst,
        isLoading,
        setIsLoading,
        showPassword,
        setShowPassword,
        stickyNav,
        setstickyNav,
        toTop,
        settoTop,
        active,
        setActive,
        activeCV,
        setActiveCV,
        interviewName,
        setInterviewName,
        reportData,
        setReportData,
        timerValue,
        setTimerValue,
        isTimerRunning,
        setIsTimerRunning,
        formatTimer,
        levelDescriptions,
        levelCodes,
        language,
        fname,
        mname,
        lname,
        email,
        pi_id,
        age,
        gender,
        race,
        education,
        radio11,
        radio12,
        radio13,
        checkbox1_pi,
        checkbox2_pi,
        setCheckbox1_pi,
        setCheckbox2_pi,
        setLanguage,
        setFname,
        setMname,
        setLname,
        setEmail,
        setpi_id,
        setAge,
        setGender,
        setRace,
        setEducation,
        setRadio11,
        setRadio12,
        setRadio13,
        setSuperToken,
        preLoader,
        setPreLoader,
        countryCodes
      }}
    >
      {children}
      {/* Success Toast Component */}
      <Snackbar
        open={isToastOpen}
        autoHideDuration={3000}
        onClose={handleToastClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success" onClose={handleToastClose}>
          {toastMessage}
        </Alert>
      </Snackbar>
      {/* Error Toast Component */}
      <Snackbar
        open={isErrorOpen}
        autoHideDuration={3000}
        onClose={handleErrorClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error" onClose={handleErrorClose}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </AuthContext.Provider>
  );
};

export const UserLogin = () => useContext(AuthContext);

export default AuthProvider;
