import React, { useEffect, useState } from 'react';
import { UserLogin } from '../../../context/AuthContext';
import axios from 'axios';
import { GET_ACTIVE_ORGANIZATION } from '../../../Auth_API';
import "../../../index.css";

const ActiveOrganization = () => {
    const [activeOrganization, setActiveOrganization] = useState([]);
    const [toggle, setToggle] = useState(true);
    const { superToken } = UserLogin();

    const getActiveOrganization = async () => {
        await axios.get(`${GET_ACTIVE_ORGANIZATION}`, {
            headers: {
                'Authorization': `Token ${superToken}`,
            }
        }).then((response) => {
            console.log("org data:",response.data);
            
            setActiveOrganization(response.data);
        });
    };

    const onTableScroll = () => {
        const tableElement = document.querySelector(".table-responsive");
        setToggle(!toggle);
        tableElement.style.overflowY = toggle ? "auto" : "hidden";
    };

    useEffect(() => {
        if (superToken) {
            getActiveOrganization();
        }
    }, [superToken]);

    return (
        <div className='mt-md-4 mt-3 bg-organization shadow-sm' data-tour="3" >
            <div className="d-flex flex-sm-row flex-column justify-content-between align-items-center">
                <h4 className="admin-active-title">Active Organizations</h4>
            </div>
            <div className='table-responsive'>
                <table className="table table-borderless mt-md-0 table-scroll">
                    <thead>
                        <tr>
                            <th scope="col">Name</th>
                            <th scope="col">Country</th>
                            <th scope="col">City</th>
                            <th scope="col">Users</th>
                            <th scope="col">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activeOrganization.length !== 0 && activeOrganization.map((item, index) => (
                            <tr key={index}>
                                <td className='font-style'>{item.name}</td>
                                <td className='font-style'>{item.country}</td>
                                <td className='font-style'>{item.city}</td>
                                <td className='font-style'>{item.user_count}</td>
                                <td><span className={`${item.status === 'active' ? "active-status" : "inactive-status"}`}>{item.status}</span></td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default ActiveOrganization;
